import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Tooltip, Typography } from "antd";
import { GRID_GUTTER } from "constants/ui";
import {
  EVENT_PLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import {
  METRIC_EVENT_COUNT,
  METRIC_PAGE_VIEWS,
  METRIC_TOTAL_USERS,
} from "constants/metrics";
import { get, isEmpty, orderBy, sum } from "lodash";
import { DIMENSION_EVENT_NAME, DIMENSION_EVENT_URL, DIMENSION_HOSTNAME, DIMENSION_SOURCE_MEDIUM } from "constants/dimensions";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
} from "constants/customDimensions";
import LineChart from "components/charts/LineChart";
import TableChart from "components/charts/TableChart";
import ContentLink from "components/ContentLink";
import ContentType from "components/ContentType";
import { formatNumber } from "services/numberService";
import Icon from "components/Icon";
import { getContents } from "services/gaService";



const TopViewsSection = ({
  topContentAddedToStackReportData,
  topPageViewsReportData,
  trafficSourcesReportData
}) => {
  const [contentMaps, setContentMaps] = useState({});
  const contentActiveViewsTableData = useMemo(() => {
    if (isEmpty(topContentAddedToStackReportData)) return [];
    const result = {};
    for(const row of topContentAddedToStackReportData){
      const itemId = row[DIMENSION_ITEM_ID];
      if (!result[itemId]) result[itemId] = {};
      result[itemId][DIMENSION_ITEM_ID] = itemId;
      // result[itemId][DIMENSION_HOSTNAME] = row[DIMENSION_HOSTNAME];
      // result[itemId][DIMENSION_ITEM_NAME] = row[DIMENSION_ITEM_NAME];
      // result[itemId][DIMENSION_ITEM_BRAND] = row[DIMENSION_ITEM_BRAND];
      // result[itemId][DIMENSION_ITEM_CATEGORY] = row[DIMENSION_ITEM_CATEGORY];
      const contentMap = contentMaps[itemId];
      if(contentMap){
        result[itemId][DIMENSION_ITEM_NAME] = contentMap.name;
        result[itemId][DIMENSION_ITEM_BRAND] = contentMap.ownerDomain;
        result[itemId][DIMENSION_ITEM_CATEGORY] = get(contentMap.tags, '[0]', '');
      }
      if(row[DIMENSION_EVENT_NAME] === EVENT_PLUG_ITEM){
      result[itemId][EVENT_PLUG_ITEM] = (result[itemId][EVENT_PLUG_ITEM] || 0 ) + (row[METRIC_EVENT_COUNT] || 0);
      }
      if(row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION){
      result[itemId][EVENT_VIEW_ITEM_IMPRESSION] = (result[itemId][EVENT_VIEW_ITEM_IMPRESSION] || 0 ) + (row[METRIC_EVENT_COUNT] || 0);
      }
      if(row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS){
      result[itemId][EVENT_VIEW_ITEM_DETAILS] = (result[itemId][EVENT_VIEW_ITEM_DETAILS] || 0 ) + (row[METRIC_EVENT_COUNT] || 0);
      }
      if(row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED){
      result[itemId][EVENT_VIEW_ITEM_CLICKED] = (result[itemId][EVENT_VIEW_ITEM_CLICKED] || 0 ) + (row[METRIC_EVENT_COUNT] || 0);
      }
    }

    return orderBy(
      Object.values(result),
      [EVENT_PLUG_ITEM],
      ["desc"]
    );
  }, [topContentAddedToStackReportData, contentMaps]);
  useEffect(() => {
    const loadContents = async () => {
      const contentIds = contentActiveViewsTableData.map(item => item[DIMENSION_ITEM_ID]);
      const newIds = contentIds.filter(id => !contentMaps[id]);
      if (newIds.length === 0) return;

      try {
        const contents = await getContents(newIds);
        setContentMaps(prevMaps => {
          const newMaps = {...prevMaps};
          for (const contentId of newIds) {
            newMaps[contentId] = contents.find(content => content.id === contentId) || {id: contentId,};
          }
          return newMaps;
        });
      } catch (error) {
        console.error('Error loading contents:', error);
      }
    };

    loadContents();
  }, [contentActiveViewsTableData, contentMaps]);
  return (
    <Row gutter={GRID_GUTTER}>
      {/* <Col xs={24} lg={12} className="mb-8">
        <Typography.Title level={5}>Top pages</Typography.Title>
        <Card size="small" className="mb-8">
          <TableChart
            rowKey={DIMENSION_EVENT_URL}
            dataSource={topPageViewsReportData}
            columns={[
              {
                title: "Url",
                dataIndex: DIMENSION_EVENT_URL,
                key: DIMENSION_EVENT_URL,
                ellipsis: true,
                render: (_, record) => (
                  
                  <span>
                    <a href={record[DIMENSION_EVENT_URL]} target="_blank" rel="noreferrer"><Icon name="external-link" className="mr-2"></Icon></a>
                    <Tooltip title={record[DIMENSION_EVENT_URL]}>
                    {record[DIMENSION_EVENT_URL]}
                    </Tooltip>
                    </span>
                )
              },
              {
                title: "Page Views",
                dataIndex: METRIC_PAGE_VIEWS,
                key: METRIC_PAGE_VIEWS,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },
              {
                title: "Visitors",
                dataIndex: METRIC_TOTAL_USERS,
                key: METRIC_TOTAL_USERS,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },
            ]}
          />
        </Card>
        <Typography.Title level={5}>Traffic sources</Typography.Title>
        <Card size="small" className="">
          <TableChart
            rowKey={DIMENSION_EVENT_URL}
            dataSource={trafficSourcesReportData}
            columns={[
              {
                title: "Source / medium",
                dataIndex: DIMENSION_SOURCE_MEDIUM,
                key: DIMENSION_SOURCE_MEDIUM,              
              },             
              {
                title: "Visitors",
                dataIndex: METRIC_TOTAL_USERS,
                key: METRIC_TOTAL_USERS,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },
            ]}
          />
        </Card>
      </Col> */}
      {/* <Col xs={24} lg={12} className="mb-8"> */}
      <Col  className="mb-8">
        <Typography.Title level={5}>
          Top contents added to stack
        </Typography.Title>
        <Card size="small" className="">
          <TableChart
            rowKey={DIMENSION_ITEM_ID}
            dataSource={contentActiveViewsTableData.filter(item => item[DIMENSION_ITEM_NAME] )}
            // syncNames={syncNamesOptions}
            columns={[
              {
                title: "Content",
                dataIndex: DIMENSION_ITEM_ID,
                key: DIMENSION_ITEM_ID,
                ellipsis: true,
                render: (_, record) => (
                  <ContentLink
                    id={record[DIMENSION_ITEM_ID]}
                    name={record[DIMENSION_ITEM_NAME]}
                    tag={record[DIMENSION_ITEM_CATEGORY]}
                    ownerDomain={record[DIMENSION_ITEM_BRAND]}
                  />
                ),
              },  
              {
                title: "Impressions",
                dataIndex: EVENT_VIEW_ITEM_IMPRESSION,
                key: EVENT_VIEW_ITEM_IMPRESSION,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },           
              // {
              //   title: "Views",
              //   dataIndex: EVENT_VIEW_ITEM_DETAILS,
              //   key: EVENT_VIEW_ITEM_DETAILS,
              //   width: 120,
              //   align: "right",
              //   render: (value) => formatNumber(value),
              // },
              // {
              //   title: "Clicks",
              //   dataIndex: EVENT_VIEW_ITEM_CLICKED,
              //   key: EVENT_VIEW_ITEM_CLICKED,
              //   width: 120,
              //   align: "right",
              //   render: (value) => formatNumber(value),
              // },
              {
                title: "Adds",
                dataIndex: EVENT_PLUG_ITEM,
                key: EVENT_PLUG_ITEM,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
};

TopViewsSection.propTypes = {};

export default TopViewsSection;
