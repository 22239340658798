import React, { useMemo } from "react";
import { Card, Col, Divider, Row, Space, Tooltip } from "antd";
import { isEmpty, sum, get } from "lodash";
import {
  GRID_GUTTER,
  GRID_GUTTER_SM,
  LABEL_INACTIVE,
  TOOLTIP_ACTIVE_VIEWS,
  TOOLTIP_CARD,
  TOOLTIP_CLICKS,
  TOOLTIP_CLICK_OPEN_RATE,
  TOOLTIP_CLICK_RATE,
  TOOLTIP_DEEP_CLICKS,
  TOOLTIP_OPEN_RATE,
  TOOLTIP_PUBLICATION,
} from "constants/ui";
import ContentLink from "components/ContentLink";
import ChartTitle from "components/ChartTitle";
import moment from "moment";
import { DATE_FORMAT } from "constants/default";
import CardPreview from "../CardPreview";
import Icon from "components/Icon";
import { PARAM_CONTENT_TYPE } from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";
import { useParams } from "react-router-dom";
import { useContentViewsContext } from "../../ContentViewsContext";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import { DIMENSION_ITEM_SOURCE } from "constants/customDimensions";
import {
  SOURCE_EMAIL,
  SOURCE_LIGHTWEIGHT,
  SOURCE_MOBILE,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import Scorecard from "components/charts/Scorecard";
import { indigo300, pink300, purple300, sky300 } from "constants/colors";
import { formatNumber } from "services/numberService";
import { clientClickSourcesMap } from "../../clickSourcesMap";
import { clientDeepClickSourcesMap } from "../../deepClickSourcesMap";
import TranslatedText from "components/TranslatedText";

const summaryBoxStyle = { padding: "4px 12px" };
function Summary() {
  const {
    summaryReport,
    contentData,
    emailSourcesMap,
    deepClicksReport,
    useSendInBlueReport,
    sendInBlueReport,
    emailEventsMap,
    clickSourcesMap,
  } = useContentViewsContext();
  const { id: contentId } = useParams();
  const { [PARAM_CONTENT_TYPE]: contentType } = useQueryString();
  const emailSentCount = useMemo(() => {
    const gaStats = Object.values(emailSourcesMap).reduce(
      (total, emailSource) => {
        if (
          useSendInBlueReport &&
          emailSource.key.indexOf("premiumemail") !== -1
        ) {
          // when using SendInBlue report, ignore the item_details ga stats
          return total;
        }

        const eventCount =
          (emailEventsMap[emailSource.key] || {}).activeViews || 0;
        const hasEvents = eventCount >= emailSource.triggerThreshold;

        if (hasEvents) return emailSource.sent + total;

        return total;
      },
      0
    );
    const sibReport = useSendInBlueReport
      ? get(sendInBlueReport, "statistics.campaignStats[0].sent", 0)
      : 0;
    return gaStats + sibReport;
  }, [emailSourcesMap, useSendInBlueReport, sendInBlueReport, emailEventsMap]);

  // IMPRESSIONS
  const emailImpressionCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
    );
    const total = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return total;
  }, [summaryReport]);

  const plugiloImpressionCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_LIGHTWEIGHT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);
  const mobileImpressionCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_MOBILE
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const widgetImpressionCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGIT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  // ACTIVE VIEWS
  const plugiloViewCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_LIGHTWEIGHT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const mobileViewCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_MOBILE
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const widgetViewCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGIT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const emailViewCount = useMemo(() => {
    if (useSendInBlueReport) {
      const sibViewCount = get(
        sendInBlueReport,
        "statistics.campaignStats[0].viewed",
        0
      );
      return sibViewCount;
    }

    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_EMAIL_DETAILS &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
    );

    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [sendInBlueReport, summaryReport, useSendInBlueReport]);

  // CLICKS
  const plugiloClickCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_LIGHTWEIGHT)
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (clickSourcesMap[clientClickSourcesMap.plugiloPortal.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [clickSourcesMap, summaryReport]);
  const mobileClickCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_MOBILE
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (clickSourcesMap[clientClickSourcesMap.plugiloPortal.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [clickSourcesMap, summaryReport]);

  const widgetClickCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGIT)
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (clickSourcesMap[clientClickSourcesMap.plugiloWidget.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [clickSourcesMap, summaryReport]);

  const emailClickCount = useMemo(() => {
    if (isEmpty(emailEventsMap)) return 0;

    return sum(Object.values(emailEventsMap).map((item) => item.clicks));
  }, [emailEventsMap]);

  // DEEP CLICKS
  const plugiloDeepClickCount = useMemo(() => {
    if (isEmpty(deepClicksReport)) return 0;
    const filteredrows = deepClicksReport.rows.filter(
      (row) =>
        // row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_LINK_CLICKED &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO ||
        row[DIMENSION_ITEM_SOURCE] === SOURCE_LIGHTWEIGHT
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (clickSourcesMap[clientDeepClickSourcesMap.plugiloPortal.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [clickSourcesMap, deepClicksReport]);

  const widgetDeepClickCount = useMemo(() => {
    if (isEmpty(deepClicksReport)) return 0;
    const filteredrows = deepClicksReport.rows.filter(
      (row) =>
        // row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_LINK_CLICKED &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET ||
        row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGIT
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (clickSourcesMap[clientDeepClickSourcesMap.plugiloWidget.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [clickSourcesMap, deepClicksReport]);

  const emailDeepClickCount = useMemo(() => {
    if (useSendInBlueReport) {
      const linksStats = get(sendInBlueReport, "statistics.linksStats", {});
      return sum(Object.keys(linksStats).map((key) => linksStats[key] || 0));
    }

    if (isEmpty(emailEventsMap)) return 0;
    return sum(Object.values(emailEventsMap).map((item) => item.deepClicks));
  }, [emailEventsMap, sendInBlueReport, useSendInBlueReport]);

  // TOP LEVEL
  const publicationCount = useMemo(() => {
    return plugiloImpressionCount + widgetImpressionCount + emailSentCount;
  }, [emailSentCount, plugiloImpressionCount, widgetImpressionCount]);

  const viewCount = useMemo(() => {
    return (
      plugiloViewCount + widgetViewCount + emailViewCount + emailImpressionCount
    );
  }, [emailImpressionCount, emailViewCount, plugiloViewCount, widgetViewCount]);

  const clickOnCardsCount = useMemo(() => {
    const sum = plugiloClickCount + widgetClickCount + emailClickCount;
    return sum;
  }, [emailClickCount, plugiloClickCount, widgetClickCount]);

  const deepClickCount = useMemo(() => {
    const sum =
      plugiloDeepClickCount + widgetDeepClickCount + emailDeepClickCount;
    return sum;
  }, [emailDeepClickCount, plugiloDeepClickCount, widgetDeepClickCount]);

  const getValueStyle = (value) => {
    const defaultValueStyle = { fontSize: "14px" };
    if (!value) {
      return {
        ...defaultValueStyle,
        color: "rgb(156, 163, 175)",
      };
    }

    return defaultValueStyle;
  };

  return (
    <Row gutter={GRID_GUTTER}>
      <Col xs={24} lg={12} className="mb-8 lg:mb-0">
        <div className="h-full flex flex-col">
          <div className="flex items-center">
            <ChartTitle>
              <Space size="middle">
                <Tooltip
                  title={<span className="text-xxs">{TOOLTIP_CARD}</span>}
                >
                  <Space size={2}>
                    plugilo Card
                    <Icon
                      name="info-circle"
                      className="text-sm text-gray-400"
                    />
                  </Space>
                </Tooltip>
                <ContentLink
                  id={contentData.id}
                  name={contentData.name}
                  tag={contentType}
                  ownerDomain={contentData.ownerDomain}
                >
                  <Icon name="arrow-up-right-from-square" />
                </ContentLink>
              </Space>
            </ChartTitle>
            <Space className="ml-auto text-gray-600 text-xxs">
              <Icon name="calendar" />
              {moment(contentData.createdDate).format(DATE_FORMAT)}
            </Space>
          </div>

          <Card
            size="small"
            className="flex-grow flex items-center justify-center"
          >
            <CardPreview
              className="w-full h-full block rounded-lg"
              contentId={contentId}
              contentType={contentType}
            />
          </Card>
        </div>
      </Col>

      <Col xs={24} lg={12} className="mb-8 lg:mb-0">
        <Space size="large" direction="vertical" className="w-full">
          <Space size={0} direction="vertical" className="w-full">
            <ChartTitle>Total</ChartTitle>
            <Row gutter={16}>
              <Col span={6}>
                <Tooltip
                  title={
                    <span className="text-xxs">{TOOLTIP_PUBLICATION}</span>
                  }
                >
                  <Card size="small">
                    <Scorecard
                      title={
                        <Space>
                          <span>Publications</span>
                          <Icon name="info-circle" />
                        </Space>
                      }
                      value={
                        publicationCount
                          ? formatNumber(publicationCount)
                          : LABEL_INACTIVE
                      }
                      valueStyle={{ color: sky300 }}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={6}>
                <Tooltip
                  title={
                    <span className="text-xxs">{TOOLTIP_ACTIVE_VIEWS}</span>
                  }
                >
                  <Card size="small">
                    <Scorecard
                      title={
                        <Space>
                          <span>Active Views</span>
                          <Icon name="info-circle" />
                        </Space>
                      }
                      value={
                        viewCount ? formatNumber(viewCount) : LABEL_INACTIVE
                      }
                      valueStyle={{ color: indigo300 }}
                    />
                  </Card>
                </Tooltip>
              </Col>
              {Boolean(clickOnCardsCount) && (
                <Col span={6}>
                  <Tooltip
                    title={<span className="text-xxs">{TOOLTIP_CLICKS}</span>}
                  >
                    <Card size="small">
                      <Scorecard
                        title={
                          <Space>
                            <span>
                              <TranslatedText id="Plugilo.Analytics.ClicksToTheContent" />
                            </span>
                            <Icon name="info-circle" />
                          </Space>
                        }
                        value={
                          clickOnCardsCount
                            ? formatNumber(clickOnCardsCount)
                            : LABEL_INACTIVE
                        }
                        valueStyle={{ color: purple300 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
              )}
              {Boolean(deepClickCount) && (
                <Col span={6}>
                  <Tooltip
                    title={
                      <span className="text-xxs">{TOOLTIP_DEEP_CLICKS}</span>
                    }
                  >
                    <Card size="small">
                      <Scorecard
                        title={
                          <Space>
                            <TranslatedText id="Plugilo.Analytics.ClickOuts" />
                            <Icon name="info-circle" />
                          </Space>
                        }
                        value={
                          deepClickCount
                            ? formatNumber(deepClickCount)
                            : LABEL_INACTIVE
                        }
                        valueStyle={{ color: pink300 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Space>

          {/* <Space size={0} direction="vertical" className="w-full">
            <Row gutter={16}>
              <Col span={8}>
                <Tooltip
                  title={<span className="text-xxs">{TOOLTIP_OPEN_RATE}</span>}
                >
                  <Card size="small">
                    <Scorecard
                      title={
                        <Space>
                          <span>Open Rate (%)</span>
                          <Icon name="info-circle" />
                        </Space>
                      }
                      value={
                        publicationCount && viewCount
                          ? formatNumber(viewCount/publicationCount*100)
                          : LABEL_INACTIVE
                      }
                      valueStyle={{ color: sky300 }}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip
                  title={<span className="text-xxs">{TOOLTIP_CLICK_RATE}</span>}
                >
                  <Card size="small">
                    <Scorecard
                      title={
                        <Space>
                          <span>Click Rate (%)</span>
                          <Icon name="info-circle" />
                        </Space>
                      }
                      value={
                        (clickOnCardsCount || deepClickCount) && publicationCount ? formatNumber(((clickOnCardsCount || 0) + (deepClickCount || 0))/publicationCount*100) : LABEL_INACTIVE
                      }
                      valueStyle={{ color: indigo300 }}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip
                  title={<span className="text-xxs">{TOOLTIP_CLICK_OPEN_RATE}</span>}
                >
                  <Card size="small">
                    <Scorecard
                      title={
                        <Space>
                          <span>Click Open-Rate (%)</span>
                          <Icon name="info-circle" />
                        </Space>
                      }
                      value={
                        (clickOnCardsCount || deepClickCount) && viewCount ? formatNumber(((clickOnCardsCount || 0) + (deepClickCount || 0))/viewCount*100) : LABEL_INACTIVE
                      }
                      valueStyle={{ color: indigo300 }}
                    />
                  </Card>
                </Tooltip>
              </Col>
            </Row>
          </Space> */}

          <Divider className="my-0" style={{ margin: 0 }} />

          <Space size={0} direction="vertical" className="w-full">
            <Tooltip
              title={<span className="text-xxs">{TOOLTIP_PUBLICATION}</span>}
            >
              <Space className="mb-2">
                <span className="text-base font-semibold mb-0">
                  Publications
                </span>{" "}
                (Number of sent emails & Impressions)
                <Icon name="info-circle" />
              </Space>
            </Tooltip>
            <Row gutter={GRID_GUTTER_SM}>
              <Col span={6} lg={6}>
                <Card size="small" bodyStyle={summaryBoxStyle}>
                  <Scorecard
                    title="Emails"
                    value={emailSentCount || LABEL_INACTIVE}
                    valueStyle={getValueStyle(emailSentCount)}
                  />
                </Card>
              </Col>
              <Col span={6} lg={6}>
                <Tooltip>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="Widgets"
                      value={widgetImpressionCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(widgetImpressionCount)}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={6} lg={6}>
                <Tooltip>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="plugilo"
                      value={plugiloImpressionCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(plugiloImpressionCount)}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={6} lg={6}>
                <Tooltip>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="Mobile"
                      value={mobileImpressionCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(mobileImpressionCount)}
                    />
                  </Card>
                </Tooltip>
              </Col>
            </Row>
          </Space>

          <Space size={0} direction="vertical" className="w-full">
            <Tooltip
              title={<span className="text-xxs">{TOOLTIP_ACTIVE_VIEWS}</span>}
            >
              <Space className="mb-2">
                <span className="text-base font-semibold mb-0">
                  Active Views
                </span>{" "}
                (All Openings)
                <Icon name="info-circle" />
              </Space>
            </Tooltip>
            <Row gutter={GRID_GUTTER_SM}>
              <Col span={6} lg={6}>
                <Card size="small" bodyStyle={summaryBoxStyle}>
                  <Scorecard
                    title="Emails"
                    value={
                      emailViewCount + emailImpressionCount || LABEL_INACTIVE
                    }
                    valueStyle={getValueStyle(
                      emailViewCount + emailImpressionCount
                    )}
                  />
                </Card>
              </Col>
              <Col span={6} lg={6}>
                <Tooltip>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="Widgets"
                      value={widgetViewCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(widgetViewCount)}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={6} lg={6}>
                <Tooltip>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="plugilo"
                      value={plugiloViewCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(plugiloViewCount)}
                    />
                  </Card>
                </Tooltip>
              </Col>
              <Col span={6} lg={6}>
                <Tooltip>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="Mobile"
                      value={mobileViewCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(mobileViewCount)}
                    />
                  </Card>
                </Tooltip>
              </Col>
            </Row>
          </Space>

          {Boolean(
            emailClickCount || widgetClickCount || plugiloClickCount
          ) && (
            <Space size={0} direction="vertical" className="w-full">
              <Tooltip
                title={<span className="text-xxs">{TOOLTIP_CLICKS}</span>}
              >
                <Space className="mb-2">
                  <span className="text-base font-semibold mb-0">
                    <TranslatedText id="Plugilo.Analytics.ClicksToTheContent" />
                  </span>{" "}
                  <Icon name="info-circle" />
                </Space>
              </Tooltip>
              <Row gutter={GRID_GUTTER_SM}>
                <Col span={6} lg={6}>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="Emails"
                      // value={(clickCount ? emailClickCount : 0) || LABEL_INACTIVE}
                      value={emailClickCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(emailClickCount)}
                    />
                  </Card>
                </Col>
                <Col span={6} lg={6}>
                  <Tooltip>
                    <Card size="small" bodyStyle={summaryBoxStyle}>
                      <Scorecard
                        title="Widgets"
                        // value={
                        //   (clickCount ? widgetClickCount : 0) || LABEL_INACTIVE
                        // }
                        value={widgetClickCount || LABEL_INACTIVE}
                        valueStyle={getValueStyle(widgetClickCount)}
                      />
                    </Card>
                  </Tooltip>
                </Col>
                <Col span={6} lg={6}>
                  <Tooltip>
                    <Card size="small" bodyStyle={summaryBoxStyle}>
                      <Scorecard
                        title="plugilo"
                        value={plugiloClickCount || LABEL_INACTIVE}
                        // value={
                        //   (clickCount ? plugiloClickCount : 0) || LABEL_INACTIVE
                        // }
                        valueStyle={getValueStyle(plugiloClickCount)}
                      />
                    </Card>
                  </Tooltip>
                </Col>

                <Col span={6} lg={6}>
                  <Tooltip>
                    <Card size="small" bodyStyle={summaryBoxStyle}>
                      <Scorecard
                        title="Mobile"
                        value={mobileClickCount || LABEL_INACTIVE}
                        valueStyle={getValueStyle(mobileClickCount)}
                      />
                    </Card>
                  </Tooltip>
                </Col>
              </Row>
            </Space>
          )}

          {Boolean(
            emailDeepClickCount || widgetDeepClickCount || plugiloDeepClickCount
          ) && (
            <Space size={0} direction="vertical" className="w-full">
              <Tooltip
                title={<span className="text-xxs">{TOOLTIP_DEEP_CLICKS}</span>}
              >
                <Space className="mb-2">
                  <span className="text-base font-semibold mb-0">
                    <TranslatedText id="Plugilo.Analytics.ClickOuts" />
                  </span>
                  <Icon name="info-circle" />
                </Space>
              </Tooltip>
              <Row gutter={GRID_GUTTER_SM}>
                <Col span={8} lg={8}>
                  <Card size="small" bodyStyle={summaryBoxStyle}>
                    <Scorecard
                      title="Emails"
                      value={emailDeepClickCount || LABEL_INACTIVE}
                      valueStyle={getValueStyle(emailDeepClickCount)}
                    />
                  </Card>
                </Col>
                <Col span={8} lg={8}>
                  <Tooltip>
                    <Card size="small" bodyStyle={summaryBoxStyle}>
                      <Scorecard
                        title="Widgets"
                        value={widgetDeepClickCount || LABEL_INACTIVE}
                        valueStyle={getValueStyle(widgetDeepClickCount)}
                      />
                    </Card>
                  </Tooltip>
                </Col>
                <Col span={8} lg={8}>
                  <Tooltip>
                    <Card size="small" bodyStyle={summaryBoxStyle}>
                      <Scorecard
                        title="plugilo"
                        value={plugiloDeepClickCount || LABEL_INACTIVE}
                        valueStyle={getValueStyle(plugiloDeepClickCount)}
                      />
                    </Card>
                  </Tooltip>
                </Col>
              </Row>
            </Space>
          )}
        </Space>
      </Col>
    </Row>
  );
}

Summary.propTypes = {};

export default Summary;
