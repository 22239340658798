import React, { useMemo } from "react";
import ChartTitle from "components/ChartTitle";
import { Card, Col, Empty, Row, Statistic, Tooltip } from "antd";
import { GRID_GUTTER, HIDE_VISITORS, LABEL_INACTIVE } from "constants/ui";
import {
  EVENT_PLUG_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import {
  METRIC_EVENT_COUNT,
  METRIC_TOTAL_USERS,
} from "constants/metrics";
import {  isEmpty, sum } from "lodash";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
} from "constants/dimensions";
import { reviseTimeSeriesData } from "services/gaService";
import LineChart from "components/charts/LineChart";
import {
  DIMENSION_ITEM_CATEGORY,
} from "constants/customDimensions";
import { TAG_CONTENT_TAGS } from "constants/contentTags";


const OverviewSection = ({  
  contentViewsReportData, 
  dateStrings,
}) => {

 

  const contentImpressionTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const rowsGroupedByDate = contentViewsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};
        result[date].date = date;
        result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
          result[date][EVENT_VIEW_ITEM_IMPRESSION],
           current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        result[date][EVENT_VIEW_ITEM_DETAILS] = sum([
          result[date][EVENT_VIEW_ITEM_DETAILS],
          current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        result[date][EVENT_VIEW_ITEM_CLICKED] = sum([
          result[date][EVENT_VIEW_ITEM_CLICKED], 
          current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION],
    });
  }, [dateStrings, contentViewsReportData]);
  // console.log('contentViewsReportData', contentViewsReportData)
  // console.log('contentImpressionTimeSeriesData', contentImpressionTimeSeriesData)


  const contentActiveViewCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  


  const contentImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentClickCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  

  const contentAddedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_PLUG_ITEM &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentRemovedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_UNPLUG_ITEM &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);



  return (
    <>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
        <ChartTitle>Trends</ChartTitle>
          <Card size="small mb-8">
          <LineChart
              xAxisDataKey="date"
              data={contentImpressionTimeSeriesData}
              lines={[
                {
                  name: "Content Impressions",
                  dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                },
                ...(!HIDE_VISITORS
                  ? [
                      {
                        name: "Visitors",
                        dataKey: METRIC_TOTAL_USERS,
                      },
                    ]
                  : []),
              ]}
            />
          </Card>
          
        </Col>
        <Col xs={24} lg={12}>
         

          <ChartTitle>Content</ChartTitle>
          <Row gutter={GRID_GUTTER} className="mb-8">
            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Impressions"
                  value={contentImpressionCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Views"
                  value={contentActiveViewCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Clicks"
                  value={contentClickCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
          
          <Row gutter={GRID_GUTTER}>
            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Content Added"
                  value={contentAddedCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Content Removed"
                  value={contentRemovedCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8 lg:mb-0"></Col>

        <Col xs={24} lg={12}></Col>
      </Row>
    </>
  );
};

OverviewSection.propTypes = {};

export default OverviewSection;
