import React, { useEffect, useMemo } from "react";
import { isEmpty, sum, toLower, get } from "lodash";
import { useContentViewsContext } from "../../ContentViewsContext";
import {
  GRID_GUTTER,
  LABEL_INACTIVE,
  TOOLTIP_ACTIVE_VIEWS,
} from "constants/ui";
import { Card, Col, Row, Space, Typography, Tooltip } from "antd";
import Icon from "components/Icon";
import TableChart from "components/charts/TableChart";
import {
  DIMENSION_ITEM_CATEGORY2,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
} from "constants/customDimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  EMPTY_DIMENSION_VALUES,
} from "constants/dimensions";
import {
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import {
  SOURCE_EMAIL,
  SOURCE_LIGHTWEIGHT,
  SOURCE_MOBILE,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import { emerald300, indigo300, purple300, sky300 } from "constants/colors";
import PieChart from "components/charts/PieChart";
import { formatNumber } from "services/numberService";
import Text from "antd/lib/typography/Text";
import {
  clientEmailSourcesMap,
  partnerSourceNames,
} from "../../emailSourcesMap";
import LineChart from "components/charts/LineChart";
import { reviseTimeSeriesData } from "services/gaService";
import cloneDeep from "lodash/cloneDeep";
import Inactive from "components/Inactive";

function ActiveViews() {
  const {
    summaryReport,
    dateStrings,
    emailSourcesMap,
    useSendInBlueReport,
    sendInBlueReport,
    setEmailEventCount,
    setTimeSeriesData,
  } = useContentViewsContext();

  const plugiloCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_LIGHTWEIGHT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const widgetCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGIT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const mobileCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_MOBILE
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  // IMPRESSIONS
  const emailImpressionCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
    );
    const total = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return total;
  }, [summaryReport]);

  const emailViewCount = useMemo(() => {
    if (useSendInBlueReport) {
      const sibViewCount = get(
        sendInBlueReport,
        "statistics.campaignStats[0].viewed",
        0
      );
      return sibViewCount;
    }

    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_EMAIL_DETAILS &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
    );

    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [sendInBlueReport, summaryReport, useSendInBlueReport]);

  const emailCount = useMemo(() => {
    return emailViewCount + emailImpressionCount;
  }, [emailViewCount, emailImpressionCount]);

  const emailSources = useMemo(() => {
    if (isEmpty(summaryReport)) return [];

    const resultMap = cloneDeep(emailSourcesMap);
    const filteredEventViewItem = useSendInBlueReport
      ? [EVENT_VIEW_ITEM_IMPRESSION]
      : [EVENT_VIEW_ITEM_EMAIL_DETAILS, EVENT_VIEW_ITEM_IMPRESSION];
    summaryReport.rows
      .filter(
        (row) =>
          filteredEventViewItem.includes(row[DIMENSION_EVENT_NAME]) &&
          row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
      )
      .forEach((current) => {
        const cId = current[DIMENSION_ITEM_SOURCE_STREAM_ID];
        const category2 = current[DIMENSION_ITEM_CATEGORY2];
        const sourceName = current[DIMENSION_ITEM_SOURCE_NAME];
        const partnerSourceName = partnerSourceNames.includes(
          current[DIMENSION_ITEM_SOURCE_NAME]
        )
          ? current[DIMENSION_ITEM_SOURCE_NAME]
          : null;
        let itemCategory = toLower(partnerSourceName || category2);
        // Fallback
        if (
          EMPTY_DIMENSION_VALUES.includes(itemCategory) &&
          EMPTY_DIMENSION_VALUES.includes(sourceName)
        )
          itemCategory = clientEmailSourcesMap.PremiumEmail.key;
        let matchEmailSource = Object.values(resultMap).find((item) => {
          return item.key === cId || item.names.includes(cId);
        });

        if (!matchEmailSource) {
          matchEmailSource = Object.values(resultMap).find((item) => {
            return item.names.includes(itemCategory);
          });
        }

        if (matchEmailSource) {
          matchEmailSource[METRIC_EVENT_COUNT] = sum([
            matchEmailSource[METRIC_EVENT_COUNT],
            current[METRIC_EVENT_COUNT],
          ]);
          resultMap[matchEmailSource.key] = matchEmailSource;
        }
      }, {});

    return Object.values(resultMap).map((item) => ({
      ...item,
      [METRIC_EVENT_COUNT]: item[METRIC_EVENT_COUNT],
    }));
  }, [emailSourcesMap, summaryReport, useSendInBlueReport]);

  const displayEmailSources = useMemo(() => {
    const sourceMap = cloneDeep(emailSources).reduce((prev, current) => {
      if (prev[current.displayName]) {
        prev[current.displayName][METRIC_EVENT_COUNT] =
          (prev[current.displayName][METRIC_EVENT_COUNT] || 0) +
          (current[METRIC_EVENT_COUNT] || 0);
      } else prev[current.displayName] = current;
      return prev;
    }, {});

    if (useSendInBlueReport) {
      // // hard code for send in blue
      return [
        {
          key: "premiumemail",
          displayName: "DCI Premium Email",
          names: ["PremiumEmail"],
          sent: 11000,
          triggerThreshold: 50,
          eventCount: emailViewCount,
        },
        ...Object.values(sourceMap),
      ];
    }

    return Object.values(sourceMap);
  }, [emailSources, emailViewCount, useSendInBlueReport]);

  const pieChartData = useMemo(() => {
    const total = plugiloCount + widgetCount + emailCount;
    const plugiloPercent = total ? (plugiloCount / total) * 100 : null;
    const widgetPercent = total ? (widgetCount / total) * 100 : null;
    const emailPercent = total ? (emailCount / total) * 100 : null;
    const mobilePercent = total ? (mobileCount / total) * 100 : null;

    return [
      {
        name: "plugilo",
        value: plugiloCount,
        color: sky300,
        percent: plugiloPercent,
      },
      {
        name: "Widgets",
        value: widgetCount,
        color: indigo300,
        percent: widgetPercent,
      },
      {
        name: "Mobile",
        value: mobileCount,
        color: emerald300,
        percent: mobilePercent,
      },
      {
        name: "Emails",
        value: emailCount,
        color: purple300,
        percent: emailPercent,
      },
    ];
  }, [emailCount, plugiloCount, widgetCount, mobileCount]);

  // TODO:
  const timeSeriesData = useMemo(() => {
    if (isEmpty(summaryReport)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = summaryReport.rows
      .filter((row) =>
        [
          EVENT_VIEW_ITEM_DETAILS,
          EVENT_VIEW_ITEM_IMPRESSION,
          EVENT_VIEW_ITEM_EMAIL_DETAILS,
        ].includes(row[DIMENSION_EVENT_NAME])
      )
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const source = current[DIMENSION_ITEM_SOURCE];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        let canCount = false;
        switch (source) {
          case SOURCE_PLUGILO:
          case SOURCE_WIDGET:
          case SOURCE_MOBILE:
          case SOURCE_PLUGIT:
          case SOURCE_LIGHTWEIGHT:
            if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
              canCount = true;
            break;

          case SOURCE_EMAIL:
            if (
              [
                EVENT_VIEW_ITEM_DETAILS,
                EVENT_VIEW_ITEM_IMPRESSION,
                EVENT_VIEW_ITEM_EMAIL_DETAILS,
              ].includes(current[DIMENSION_EVENT_NAME])
            )
              canCount = true;
            break;
          default:
            break;
        }

        if (canCount) {
          result[date][source] = sum([
            result[date][source],
            current[METRIC_EVENT_COUNT],
          ]);
          result[date][METRIC_EVENT_COUNT] = sum([
            result[date][METRIC_EVENT_COUNT],
            current[METRIC_EVENT_COUNT],
          ]);
        }
        dataKeysMap[source] = source;
        return result;
      }, {});

    const series = reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
    return series.map((x) => ({
      ...x,
      [`${SOURCE_PLUGILO}-${SOURCE_LIGHTWEIGHT}`]:
        (x[SOURCE_PLUGILO] || 0) + (x[SOURCE_LIGHTWEIGHT] || 0),
      [`${SOURCE_WIDGET}-${SOURCE_PLUGIT}`]:
        (x[SOURCE_WIDGET] || 0) + (x[SOURCE_PLUGIT] || 0),
      [SOURCE_MOBILE]: x[SOURCE_MOBILE] || 0,
    }));
  }, [dateStrings, summaryReport]);

  useEffect(() => {
    emailSources.forEach((source) => {
      setEmailEventCount(source.key, "activeViews", source[METRIC_EVENT_COUNT]);
    });
  }, [emailSources]);

  useEffect(() => {
    setTimeSeriesData("activeViews", timeSeriesData);
  }, [timeSeriesData]);

  // console.log("timeSeriesData", timeSeriesData);
  return (
    <section className="mt-8">
      <Tooltip title={<span className="text-xxs">{TOOLTIP_ACTIVE_VIEWS}</span>}>
        <Space className="mb-2">
          <span className="text-xl font-semibold mb-0">Active Views</span> (All
          Openings)
          <Icon name="info-circle" />
        </Space>
      </Tooltip>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12}>
          <Space
            direction="vertical"
            className="w-full lg:h-full flex flex-col"
            size={GRID_GUTTER}
          >
            <Card size="small">
              <TableChart
                rowKey={DIMENSION_ITEM_SOURCE}
                dataSource={[
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Portal",
                    [METRIC_EVENT_COUNT]: plugiloCount,
                  },
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Widgets",
                    [METRIC_EVENT_COUNT]: widgetCount,
                  },
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Mobile",
                    [METRIC_EVENT_COUNT]: mobileCount,
                  },
                ]}
                columns={[
                  {
                    title: "plugilo Source",
                    dataIndex: DIMENSION_ITEM_SOURCE,
                    key: DIMENSION_ITEM_SOURCE,
                    ellipsis: true,
                  },
                  {
                    title: "Views",
                    dataIndex: METRIC_EVENT_COUNT,
                    key: METRIC_EVENT_COUNT,
                    width: 100,
                    align: "right",
                    render: (value) => {
                      return value ? formatNumber(value) : <Inactive />;
                    },
                  },
                ]}
              />
            </Card>

            {!isEmpty(displayEmailSources) && (
              <Card size="small">
                <TableChart
                  rowKey="key"
                  dataSource={displayEmailSources}
                  columns={[
                    {
                      title: "Newsletter sources",
                      dataIndex: "displayName",
                      key: "displayName",
                      ellipsis: true,
                    },
                    {
                      title: "Views",
                      dataIndex: METRIC_EVENT_COUNT,
                      key: METRIC_EVENT_COUNT,
                      width: 100,
                      align: "right",
                      render: (value) => {
                        return value ? formatNumber(value) : <Inactive />;
                      },
                    },
                  ]}
                />
              </Card>
            )}
          </Space>
        </Col>

        <Col xs={24} lg={12}>
          <Card size="small" className="text-center lg:h-full mt-8 lg:mt-0">
            <div className="h-80">
              <PieChart
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                colors={[sky300, indigo300, emerald300, purple300]}
                showLegends={false}
              />
            </div>
            <Space size={40}>
              {pieChartData.map((item) => (
                <Space
                  direction="vertical"
                  size={4}
                  key={item.name}
                  className="flex-wrap justify-center"
                >
                  <Space align="baseline">
                    <Typography.Title
                      level={4}
                      style={{ color: item.color, margin: 0 }}
                    >
                      {item.value ? formatNumber(item.value) : LABEL_INACTIVE}
                    </Typography.Title>
                    {Boolean(item.percent) && (
                      <Text type="secondary">
                        ({formatNumber(item.percent)}%)
                      </Text>
                    )}
                  </Space>
                  <Text level={5}>{item.name}</Text>
                </Space>
              ))}
            </Space>
          </Card>
        </Col>
      </Row>
      <Card size="small" className="mt-8">
        <LineChart
          xAxisDataKey="date"
          data={timeSeriesData}
          lines={[
            {
              name: "plugilo",
              dataKey: `${SOURCE_PLUGILO}-${SOURCE_LIGHTWEIGHT}`,
            },
            {
              name: "Widgets",
              dataKey: `${SOURCE_WIDGET}-${SOURCE_PLUGIT}`,
            },
            {
              name: "Mobile",
              dataKey: SOURCE_MOBILE,
            },
            {
              name: "Email",
              dataKey: SOURCE_EMAIL,
            },
          ]}
        />
      </Card>
    </section>
  );
}

ActiveViews.propTypes = {};

export default ActiveViews;
