import {
  useCallback,
  useContext,
  useMemo,
  useState,
  createContext,
} from "react";
import { PARAM_SOURCE } from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";
import {
  defaultOrderBys,
  FILTER_ALL,
  sourceOptions,
} from "services/overviewService";
import { useParams } from "react-router-dom";
import {
  buildInListFilter,
  buildStringFilter,
} from "services/gaDimensionsService";
import * as customDimensions from "constants/customDimensions";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import * as customEvents from "constants/customEvents";
import { noop, isEmpty } from "lodash";
import { useAppContext } from "contexts/AppContextProvider";

const OverviewContext = createContext({
  selectedSource: "",
  setSelectedSource: noop,
  reportRequests01: null,
  reportRequests02: null,
});

const OverviewContextProvider = ({ children }) => {
  const { [PARAM_SOURCE]: paramSource } = useQueryString();
  const { id: domain } = useParams();
  const { dateStrings } = useReportContext();
  const { isPrintMode, tableRowsLimit: limit } = useAppContext();

  const dateRanges = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return [];
    return [
      {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      },
    ];
  }, [dateStrings]);

  const [selectedSource, setSelectedSource] = useState(
    paramSource || sourceOptions[0].value
  );

  const buildDefaultFilters = useCallback(
    (filters) => {
      let sourceFilter = null;
      if (selectedSource) {
        if (Array.isArray(selectedSource)) {
          sourceFilter = buildInListFilter(
            customDimensions.DIMENSION_ITEM_SOURCE,
            selectedSource
          );
        } else {
          sourceFilter = buildStringFilter(
            customDimensions.DIMENSION_ITEM_SOURCE,
            selectedSource
          );
        }
      } else {
        sourceFilter = buildInListFilter(
          customDimensions.DIMENSION_ITEM_SOURCE,
          FILTER_ALL
        );
      }

      return {
        andGroup: {
          expressions: [
            buildStringFilter(customDimensions.DIMENSION_ITEM_BRAND, domain),
            sourceFilter,
            ...(filters || []),
          ],
        },
      };
    },
    [selectedSource, domain]
  );

  const buildPrintDefaultFilter = useCallback(
    (filters, source) => ({
      andGroup: {
        expressions: [
          buildStringFilter(customDimensions.DIMENSION_ITEM_BRAND, domain),
          buildStringFilter(customDimensions.DIMENSION_ITEM_SOURCE, source),
          ...(filters || []),
        ],
      },
    }),
    [domain]
  );

  const reportRequests01 = useMemo(() => {
    if (isEmpty(dateRanges)) return;

    return [
      // 0: Views Report
      {
        dateRanges,
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: customDimensions.DIMENSION_ITEM_SOURCE,
          },
          {
            name: customDimensions.DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildDefaultFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            customEvents.EVENT_VIEW_ITEM_DETAILS,
            customEvents.EVENT_VIEW_ITEM_CLICKED,
            customEvents.EVENT_VIEW_ITEM_IMPRESSION,
            customEvents.EVENT_VIEW_ITEM_EMAIL_DETAILS,
          ]),
        ]),
      },
      // 1: Content views from selected source
      {
        dateRanges,
        dimensions: [
          {
            name: customDimensions.DIMENSION_ITEM_ID,
          },
          {
            name: customDimensions.DIMENSION_ITEM_CATEGORY,
          },
          {
            name: customDimensions.DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildDefaultFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            customEvents.EVENT_VIEW_ITEM_DETAILS,
            customEvents.EVENT_VIEW_ITEM_IMPRESSION,
          ]),
        ]),
        limit,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 2: Views from email
      {
        dateRanges,
        dimensions: [
          {
            name: customDimensions.DIMENSION_ITEM_ID,
          },
          {
            name: customDimensions.DIMENSION_ITEM_CATEGORY,
          },
          {
            name: customDimensions.DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildDefaultFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            customEvents.EVENT_VIEW_ITEM_CLICKED,
            customEvents.EVENT_VIEW_ITEM_EMAIL_DETAILS,
            customEvents.EVENT_VIEW_ITEM_IMPRESSION,
          ]),
        ]),
        limit,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 3: Plug, Save events
      {
        dateRanges,
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildDefaultFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            customEvents.EVENT_PLUG_ITEM,
            customEvents.EVENT_SAVE_ITEM,
          ]),
        ]),
      },
    ];
  }, [dateRanges, buildDefaultFilters, limit]);

  const reportRequests02 = useMemo(() => {
    if (isEmpty(dateRanges)) return;

    return [
      // 0: Contents from widget
      {
        dateRanges,
        dimensions: [
          {
            name: customDimensions.DIMENSION_ITEM_ID,
          },
          {
            name: customDimensions.DIMENSION_ITEM_CATEGORY,
          },
          {
            name: customDimensions.DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildDefaultFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            customEvents.EVENT_VIEW_ITEM_DETAILS,
          ]),
        ]),
        limit,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 1: Top clicked contents
      {
        dateRanges,
        dimensions: [
          {
            name: customDimensions.DIMENSION_ITEM_ID,
          },
          {
            name: customDimensions.DIMENSION_ITEM_CATEGORY,
          },
          {
            name: customDimensions.DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildDefaultFilters([
          buildStringFilter(
            DIMENSION_EVENT_NAME,
            customEvents.EVENT_VIEW_ITEM_CLICKED
          ),
        ]),
        limit,
        offset: 0,
        orderBys: defaultOrderBys,
      },
    ];
  }, [dateRanges, buildDefaultFilters, limit]);

  const printReportRequest01 = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return reportRequests01;
  }, [dateStrings, reportRequests01]);

  const printReportRequest02 = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    if (selectedSource) return reportRequests02;

    const topClickRequests = FILTER_ALL.map((source) => ({
      dateRanges,
      dimensions: reportRequests02[1].dimensions,
      metrics: reportRequests02[1].metrics,
      dimensionFilter: buildPrintDefaultFilter(
        [
          buildStringFilter(
            DIMENSION_EVENT_NAME,
            customEvents.EVENT_VIEW_ITEM_CLICKED
          ),
        ],
        source
      ),
      limit,
      offset: 0,
      orderBys: defaultOrderBys,
    }));

    return [reportRequests02[0], ...topClickRequests];
  }, [
    dateStrings,
    selectedSource,
    reportRequests02,
    dateRanges,
    buildPrintDefaultFilter,
    limit,
  ]);

  return (
    <OverviewContext.Provider
      value={{
        selectedSource,
        setSelectedSource,
        reportRequests01: isPrintMode ? printReportRequest01 : reportRequests01,
        reportRequests02: isPrintMode ? printReportRequest02 : reportRequests02,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export const useOverViewContext = () => useContext(OverviewContext);

export default OverviewContextProvider;
