import React, { useCallback, useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_ID_TEMP,
  DIMENSION_ITEM_SOURCE,
} from "constants/customDimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Radio, Spin } from "antd";
import {
  getBatchReports,
  getContentTypeName,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { v4 as uuid } from "uuid";
import {
  SOURCE_EMAIL,
  SOURCE_LIGHTWEIGHT,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import useSetQueryString from "hooks/useSetQueryString";
import { PARAM_SOURCE } from "constants/queryStringParams";
import { useOverViewContext } from "./OverviewContextProvider";
import ViewsTrend from "./components/ViewsTrend";
import ViewsContent from "./components/ViewsContent";
import {
  getCount,
  IGNORE_ITEM_CATEGORIES,
  sourceOptions,
} from "services/overviewService";
import { useAppContext } from "contexts/AppContextProvider";
import PrintViewContent from "./components/PrintViewContent";
import { alertUnknownError } from "services/notificationService";

const Overview = () => {
  const {
    selectedSource,
    setSelectedSource,
    reportRequests01,
    reportRequests02,
  } = useOverViewContext();

  const [reports01, setReports01] = useState([]);
  const [reports02, setReports02] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();
  const [setParam] = useSetQueryString();
  const { isPrintMode } = useAppContext();

  const hasPlugiloSource =
    isEmpty(selectedSource) ||
    selectedSource === SOURCE_PLUGILO ||
    (selectedSource.length && selectedSource.indexOf(SOURCE_PLUGILO) > -1);

  const resetData = () => {
    setReports01([]);
    setReports02([]);
  };

  useEffect(() => {
    if (!reportRequests01) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests01);
        setReports01(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests01]);

  useEffect(() => {
    if (!reportRequests02) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests02);
        setReports02(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests02]);

  const filterReport = useCallback((report) => {
    if (isEmpty(report)) return {};

    const rows = report.rows.filter((row) => {
      return !IGNORE_ITEM_CATEGORIES.includes(row[DIMENSION_ITEM_CATEGORY]);
    });

    return {
      ...report,
      rows,
    };
  }, []);

  const viewsReportData = useMemo(() => {
    return filterReport(reviseReport(reports01[0]));
  }, [filterReport, reports01]);

  const plugiloContentViewsReportData = useMemo(() => {
    return filterReport(reviseReport(reports01[1]));
  }, [filterReport, reports01]);

  const emailContentViewsReportData = useMemo(() => {
    return filterReport(reviseReport(reports01[2]));
  }, [filterReport, reports01]);

  const plugSaveReportData = useMemo(() => {
    return filterReport(reviseReport(reports01[3]));
  }, [filterReport, reports01]);

  const widgetContentsReportData = useMemo(() => {
    return filterReport(reviseReport(reports02[0]));
  }, [filterReport, reports02]);

  // Top Clicked contents
  const clickedCotentsReportData = useMemo(() => {
    return filterReport(reviseReport(reports02[1]));
  }, [filterReport, reports02]);

  const emailClickedCotentsReportData = useMemo(() => {
    return filterReport(reviseReport(reports02[2]));
  }, [filterReport, reports02]);

  const widgetClickedCotentsReportData = useMemo(() => {
    return filterReport(reviseReport(reports02[3]));
  }, [filterReport, reports02]);
  const viewsTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(viewsReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = viewsReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      const source = current[DIMENSION_ITEM_SOURCE];
      if (!result[date]) result[date] = {};

      result[date][DIMENSION_DATE] = date;
      result[date][source] = sum([result[date][source], getCount(current)]);
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        getCount(current),
      ]);
      dataKeysMap[source] = source;
      return result;
    }, {});

    const series = reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,

      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
    return series.map((x) => ({
      ...x,
      [`${SOURCE_PLUGILO}-${SOURCE_LIGHTWEIGHT}`]:
        (x[SOURCE_PLUGILO] || 0) + (x[SOURCE_LIGHTWEIGHT] || 0),
      [`${SOURCE_WIDGET}-${SOURCE_PLUGIT}`]:
        (x[SOURCE_WIDGET] || 0) + (x[SOURCE_PLUGIT] || 0),
    }));
  }, [dateStrings, viewsReportData]);

  const contentViewsByTypesPieChartData = useMemo(() => {
    if (isEmpty(viewsReportData)) return [];

    const rowsGroupedByCategory = viewsReportData.rows.reduce(
      (result, current) => {
        const category = current[DIMENSION_ITEM_CATEGORY];
        if (!result[category]) result[category] = {};

        result[category][DIMENSION_ITEM_CATEGORY] =
          getContentTypeName(category);
        result[category][METRIC_EVENT_COUNT] = sum([
          result[category][METRIC_EVENT_COUNT],
          getCount(current),
        ]);
        return result;
      },
      {}
    );

    return orderBy(Object.values(rowsGroupedByCategory), [DIMENSION_DATE]);
  }, [viewsReportData]);

  // Plugilo
  const plugiloContentViewsTableData = useMemo(() => {
    if (isEmpty(plugiloContentViewsReportData)) return [];

    return orderBy(
      Object.values(plugiloContentViewsReportData.rows),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [plugiloContentViewsReportData]);

  // Email
  const emailContentViewsTableData = useMemo(() => {
    if (isEmpty(emailContentViewsReportData)) return [];

    return orderBy(
      emailContentViewsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
  }, [emailContentViewsReportData]);

  // Widget
  const widgetContentViewsTableData = useMemo(() => {
    if (isEmpty(widgetContentsReportData)) return [];

    return orderBy(
      widgetContentsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
  }, [widgetContentsReportData]);

  const combinedContentViewsTableData = useMemo(() => {
    switch (selectedSource) {
      case SOURCE_PLUGILO:
        return plugiloContentViewsTableData;
      case SOURCE_WIDGET:
        return widgetContentViewsTableData;
      case SOURCE_EMAIL:
        return emailContentViewsTableData;
      default:
        const combinedSourceContents = [
          ...plugiloContentViewsTableData,
          ...widgetContentViewsTableData,
          ...emailContentViewsTableData,
        ];
        const contentsGroupById = combinedSourceContents.reduce(
          (result, current) => {
            const itemId = current[DIMENSION_ITEM_ID];
            if (!result[itemId]) result[itemId] = {};

            result[itemId] = {
              ...current,
              [METRIC_EVENT_COUNT]: sum([
                current[METRIC_EVENT_COUNT],
                result[itemId][METRIC_EVENT_COUNT] || 0,
              ]),
            };
            return result;
          },
          {}
        );

        return orderBy(
          Object.values(contentsGroupById),
          [METRIC_EVENT_COUNT],
          ["desc"]
        ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
    }
  }, [
    selectedSource,
    plugiloContentViewsTableData,
    widgetContentViewsTableData,
    emailContentViewsTableData,
  ]);

  // Clicked contents
  const contentClicksTableData = useMemo(() => {
    if (isEmpty(clickedCotentsReportData)) return [];

    return orderBy(
      clickedCotentsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
  }, [clickedCotentsReportData]);

  const widgetContentClicksTableData = useMemo(() => {
    if (isEmpty(widgetClickedCotentsReportData)) return [];

    return orderBy(
      widgetClickedCotentsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
  }, [widgetClickedCotentsReportData]);

  const emailContentClicksTableData = useMemo(() => {
    if (isEmpty(emailClickedCotentsReportData)) return [];

    return orderBy(
      emailClickedCotentsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
  }, [emailClickedCotentsReportData]);

  const plugSaveTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(plugSaveReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = plugSaveReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        const eventName = current[DIMENSION_EVENT_NAME];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][eventName] = sum([
          result[date][eventName],
          current[METRIC_EVENT_COUNT],
        ]);
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        dataKeysMap[eventName] = eventName;
        return result;
      },
      {}
    );

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [dateStrings, plugSaveReportData]);

  const onSourceChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSelectedSource(e.target.value);
      setParam(PARAM_SOURCE, value);
    },
    [setParam, setSelectedSource]
  );

  return (
    <Spin spinning={isLoading}>
      {!isPrintMode && (
        <div className="flex">
          <span className="ml-auto">
            <Radio.Group
              size="small"
              options={sourceOptions}
              onChange={onSourceChange}
              value={selectedSource}
              optionType="button"
              buttonStyle="solid"
            />
          </span>
        </div>
      )}
      <ViewsTrend data={viewsTrendTimeSeriesData} />
      {isPrintMode && !selectedSource ? (
        <PrintViewContent
          viewsReportData={viewsReportData}
          plugiloTableData={plugiloContentViewsTableData}
          plugSaveTrendTimeSeriesData={plugSaveTrendTimeSeriesData}
          plugiloClickData={contentClicksTableData}
          emailTableData={emailContentViewsTableData}
          emailClickData={emailContentClicksTableData}
          widgetTableData={widgetContentViewsTableData}
          widgetClickData={widgetContentClicksTableData}
        />
      ) : (
        <ViewsContent
          combinedContentViewsTableData={combinedContentViewsTableData}
          contentViewsByTypesPieChartData={contentViewsByTypesPieChartData}
          contentClicksTableData={contentClicksTableData}
          hasPlugiloSource={hasPlugiloSource}
        />
      )}
    </Spin>
  );
};

Overview.propTypes = {};

export default Overview;
