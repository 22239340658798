export const PARAM_MERGED_IDS = "mergeIds";
export const PARAM_SOURCE = "source";
export const PARAM_WIDGET_SOURCE = "wg_source";
export const PARAM_WIDGET_STREAM = "wg_stream";
export const PARAM_WIDGET_HOST = "wg_host";
export const PARAM_WIDGET_PARTNER = "wg_partner";
export const PARAM_WIDGET_ITEM_OWNER = "wg_item_owner";
export const PARAM_WIDGET_ITEM_ID = "wg_item_id";
export const PARAM_API_KEY = "api_key";
export const PARAM_PK = "pk";
export const PARAM_PRINT = "print";
export const PARAM_TABLE_ROWS_LIMIT = "table_limit";
export const PARAM_DATE_START = "start";
export const PARAM_DATE_END = "end";
export const PARAM_SID_CAMPAIGN_ID = "sib_id";
export const PARAM_CONTENT_TYPE = "content_type";
export const PARAM_IS_ADMIN = "isAdmin";
export const PARAM_TRIGGER_TEXT_HOST = "tt_host";
export const PARAM_TRIGGER_TEXT_ITEM_OWNER = "tt_item_owner";
export const PARAM_TRIGGER_TEXT = "tt_item_text";
export const PARAM_TRIGGER_TEXT_ID = "tt_item_id";
export const PARAM_TRIGGER_TEXT_OWNER = "tt_item_text_owner";
