import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_TRIGGER_TEXT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_SOURCE,
} from "constants/customDimensions";
import {
  EVENT_CLOSE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_PLUG_ITEM,
  EVENT_SAVE_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_EXTERNAL,
  EVENT_VIEW_ITEM_IMPRESSION,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import { DIMENSION_EVENT_NAME, DIMENSION_HOSTNAME } from "constants/dimensions";

const buildStringFilter = (fieldName, value) => {
  return {
    filter: {
      fieldName,
      stringFilter: {
        value,
      },
    },
  };
};

const buildInListFilter = (fieldName, values) => {
  return {
    filter: {
      fieldName,
      inListFilter: {
        values,
      },
    },
  };
};

const buildNumericFilter = (fieldName, value, operation) => {
  return {
    filter: {
      fieldName,
      numericFilter: {
        value,
        operation,
      },
    },
  };
};

const buildBetweenFilter = (fieldName, fromValue, toValue) => {
  return {
    filter: {
      fieldName,
      fromValue: {},
      toValue: {},
    },
  };
};

// Events
export const FILTER_EVENT_CLOSE_ITEM = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_CLOSE_ITEM
);

export const FILTER_EVENT_MINIMIZE_ITEM = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_MINIMIZE_ITEM
);

export const FILTER_EVENT_VIEW_ITEM_IMPRESSIONS = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_VIEW_ITEM_IMPRESSION
);

export const FILTER_EVENT_VIEW_ITEM_DETAILS = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_VIEW_ITEM_DETAILS
);

export const FILTER_EVENT_PLUG_CONTENT = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_PLUG_ITEM
);

export const FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_VIEW_ITEM_EMAIL_DETAILS
);

export const FILTER_EVENT_VIEW_ITEM_EXTERNAL = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_VIEW_ITEM_EXTERNAL
);

export const FILTER_EVENT_SAVE_ITEM = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_SAVE_ITEM
);

export const FILTER_EVENT_VIEW_ITEM_CLICKED = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_VIEW_ITEM_CLICKED
);

export const FILTER_EVENT_VIEW_ITEM_LINK_CLICKED = buildStringFilter(
  DIMENSION_EVENT_NAME,
  EVENT_VIEW_ITEM_LINK_CLICKED
);

// Sources
export const FILTER_ITEM_SOURCE_INFOBOARD = buildStringFilter(
  DIMENSION_ITEM_SOURCE,
  SOURCE_WIDGET
);

export const FILTER_ITEM_SOURCE_PLUGILO = buildStringFilter(
  DIMENSION_ITEM_SOURCE,
  SOURCE_PLUGILO
);

export const FILTER_ITEM_SOURCE_EMAIL = buildStringFilter(
  DIMENSION_ITEM_SOURCE,
  SOURCE_EMAIL
);

export const FILTER_ITEM_SOURCE_TRIGGER_TEXT = buildStringFilter(
  DIMENSION_ITEM_SOURCE,
  SOURCE_TRIGGER_TEXT
);

export const FILTER_ITEM_SOURCE_PLUGIT = buildStringFilter(
  DIMENSION_ITEM_SOURCE,
  SOURCE_PLUGIT
);

// Category
export const FILTER_ITEM_CATEGORY_INFOBOARD = buildStringFilter(
  DIMENSION_ITEM_CATEGORY,
  "infoboard"
);
export const FILTER_ITEM_CATEGORY_PLUGIT = buildStringFilter(
  DIMENSION_ITEM_CATEGORY,
  "plugit"
);




export {
  buildStringFilter,
  buildInListFilter,
  buildNumericFilter,
  buildBetweenFilter,
};
