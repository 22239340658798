import React, { useMemo } from "react";
import { Card, Col, Row, Typography } from "antd";
import { GRID_GUTTER, HIDE_VISITORS } from "constants/ui";
import { METRIC_EVENT_COUNT, METRIC_TOTAL_USERS } from "constants/metrics";
import { isEmpty, sum } from "lodash";
import { DIMENSION_ITEM_ID } from "constants/customDimensions";
import TableChart from "components/charts/TableChart";
import { formatNumber } from "services/numberService";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  EVENT_PLUG_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import { reviseTimeSeriesData } from "services/gaService";
import moment from "moment";
import { DATE_FORMAT } from "constants/default";

const DailyTrafficSection = ({
  dateStrings,
  contentViewsReportData,  
}) => {
  
  const contentImpressionTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const rowsGroupedByDate = contentViewsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};
        result[date].date = date;
        result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
          result[date][EVENT_VIEW_ITEM_IMPRESSION],
           current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        result[date][EVENT_VIEW_ITEM_DETAILS] = sum([
          result[date][EVENT_VIEW_ITEM_DETAILS],
          current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        result[date][EVENT_VIEW_ITEM_CLICKED] = sum([
          result[date][EVENT_VIEW_ITEM_CLICKED], 
          current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        result[date][EVENT_PLUG_ITEM] = sum([
          result[date][EVENT_PLUG_ITEM], 
          current[DIMENSION_EVENT_NAME] === EVENT_PLUG_ITEM ? current[METRIC_EVENT_COUNT] : 0,
        ]);
        result[date][EVENT_UNPLUG_ITEM] = sum([
          result[date][EVENT_UNPLUG_ITEM], 
          current[DIMENSION_EVENT_NAME] === EVENT_UNPLUG_ITEM ? current[METRIC_EVENT_COUNT] : 0,
        ]);

        return result;
      },
      {}
    );

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS, EVENT_VIEW_ITEM_CLICKED, EVENT_PLUG_ITEM, EVENT_UNPLUG_ITEM],
    }, "desc");
  }, [dateStrings, contentViewsReportData]);
  

  

  const activeViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const filteredRows = contentViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, contentViewsReportData]);

  const impressionsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const filteredRows = contentViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, contentViewsReportData]);

  const clicksTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const filteredRows = contentViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, contentViewsReportData]);

  const tableData = useMemo(() => {
    if (
      isEmpty(contentImpressionTimeSeriesData) 
     
    )
      return [];

    const dataLength =    
      contentImpressionTimeSeriesData.length ;

    return Array(dataLength)
      .fill()
      .map((_, index) => {
       
        const contentImpressionItem = contentImpressionTimeSeriesData[index] || {};
      
    
        return {
          ...contentImpressionItem,        
       
        
        };
      });
  }, [contentImpressionTimeSeriesData]);

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: "Date",
        dataIndex: DIMENSION_DATE,
        key: DIMENSION_DATE,
        render(value) {
          return moment(value).format(DATE_FORMAT);
        },
      },
      {
        title: "Impressions",
        dataIndex: EVENT_VIEW_ITEM_IMPRESSION,
        key: EVENT_VIEW_ITEM_IMPRESSION,
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
      },     
     
      {
        title: "Views",
        dataIndex: EVENT_VIEW_ITEM_DETAILS,
        key: EVENT_VIEW_ITEM_DETAILS,
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
      },
      {
        title: "Clicks",
        dataIndex: EVENT_VIEW_ITEM_CLICKED,
        key: EVENT_VIEW_ITEM_CLICKED,
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
      },
      {
        title: "Content Added",
        dataIndex: EVENT_PLUG_ITEM,
        key: EVENT_PLUG_ITEM,
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
      },
      {
        title: "Content Removed",
        dataIndex: EVENT_UNPLUG_ITEM,
        key: EVENT_UNPLUG_ITEM,
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
      },
    ];

    return columns.filter((column) => !column.disabled);
  }, []);

  return (
    <>
      <Typography.Title level={5}>Daily Traffic</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <Card size="small" className="">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={tableData}
              columns={tableColumns}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

DailyTrafficSection.propTypes = {};

export default DailyTrafficSection;
