import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
} from "constants/dimensions";
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_CATEGORY2,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_URL,
} from "constants/customDimensions";
import { get, first, some } from "lodash";
import { Spin, Result, message } from "antd";
import {
  canViewStatistics,
  getBatchReports,
  getContents,
  getSendInBlueReport,
  reviseReport,
} from "services/gaService";
import { buildInListFilter } from "services/gaDimensionsService";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import {
  SOURCE_EMAIL,
  SOURCE_LIGHTWEIGHT,
  SOURCE_MOBILE,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import BulkContentViewsProvider from "./BulkContentViewsContext";

import moment from "moment";
import {
  DATE_FORMAT,
  getDefaultContentDateAvailableReport,
} from "constants/default";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";
import { useAppContext } from "contexts/AppContextProvider";
import ContentSummary from "./components/ContentSummary";

const buildFilters = (filters) => {
  return {
    andGroup: {
      expressions: [...(filters || [])],
    },
  };
};

const ContentViews = () => {
  const [isInitiated, setIsInitiated] = useState(false);
  const [reports, setReports] = useState([]);
  const [contentData, setContentData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [sendInBlueReport, setSendInBlueReport] = useState({});
  const [emailEventsMap, setEmailEventsMap] = useState({});
  const { isAdmin } = useAppContext();
  const { dateStrings, setDateStrings } = useReportContext();
  const [contentIds, setContentIds] = useState([]);
  const [canViewContentIds, setCanViewContentIds] = useState([]);
  const [reportContentIds, setReportContentIds] = useState([]);

  const [error, setError] = useState();
  const setEmailEventCount = useCallback((sourceName, eventType, hasEvent) => {
    setEmailEventsMap((oldValue) => {
      return {
        ...oldValue,
        [sourceName]: { ...oldValue[sourceName], [eventType]: hasEvent },
      };
    });
  }, []);

  const reportRequests = useMemo(() => {
    if (
      !reportContentIds[0] ||
      !dateStrings ||
      !dateStrings[0] ||
      !dateStrings[1]
    )
      return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_CATEGORY2,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_ITEM_ID, reportContentIds),
          buildInListFilter(DIMENSION_ITEM_SOURCE, [
            SOURCE_PLUGILO,
            SOURCE_EMAIL,
            SOURCE_WIDGET,
            SOURCE_LIGHTWEIGHT,
            SOURCE_MOBILE,
            SOURCE_PLUGIT,
          ]),
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_DETAILS,
            EVENT_VIEW_ITEM_EMAIL_DETAILS,
            EVENT_VIEW_ITEM_IMPRESSION,
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_LINK_CLICKED,
          ]),
        ]),
      },
      // IMPRESSIONS
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_HOSTNAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_ITEM_ID, reportContentIds),
          buildInListFilter(DIMENSION_ITEM_SOURCE, [
            SOURCE_PLUGILO,
            SOURCE_WIDGET,
            SOURCE_LIGHTWEIGHT,
            SOURCE_MOBILE,
            SOURCE_PLUGIT,
          ]),
          buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_VIEW_ITEM_IMPRESSION]),
        ]),
      },
      // Click outs
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_URL,
          },
          {
            name: DIMENSION_ITEM_CATEGORY2,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_LINK_CLICKED,
          ]),
          buildInListFilter(DIMENSION_ITEM_SOURCE_ID, reportContentIds),
        ]),
      },
    ];
  }, [reportContentIds, dateStrings]);

  useEffect(() => {
    if (!contentIds.length) {
      if (window.parent !== window) {
        window.parent.postMessage(
          { action: "BULK_CONTENT_STATISTIC_READY" },
          "*"
        );
      }
    }
  }, [contentIds]);

  useLayoutEffect(() => {
    const onMessage = (e) => {
      if (e.data?.action === "BULK_CONTENT_STATISTIC_DATA") {
        if (!contentIds.length) {
          setContentIds(get(e, "data.contentIds", []));
        }
      }
    };

    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [contentIds.length]);

  useEffect(() => {
    if (!reportRequests || !isInitiated || !isValid) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests, isInitiated, isValid]);

  useEffect(() => {
    if (contentData.length) {
      const ids = [
        ...contentData
          .filter((x) => some(canViewContentIds, (y) => y === x.id))
          .map((x) => x.id),
        ...contentData.reduce((prev, current) => {
          (current.mergedContentIds || []).map((x) => prev.push(x));
          return prev;
        }, []),
      ];
      setReportContentIds(ids);
    }
  }, [contentData, canViewContentIds]);

  useEffect(() => {
    const getCanViewContentIds = async (ids) => {
      try {
        const ids = await canViewStatistics(contentIds);
        setCanViewContentIds(ids);
      } catch (err) {
        message.error("Something went wrong");
        console.error(error);
      }
      return [];
    };
    if (contentIds.length) {
      setIsInitiated(true);
      getCanViewContentIds(contentIds);
    }
  }, [contentIds]);

  useEffect(() => {
    if (!contentIds.length || !isInitiated) return;
    const loadData = async () => {
      setIsLoading(true);
      try {
        const contentData = await getContents(contentIds);
        if (contentData.length) {
          setContentData(contentData);

          const dateAvailableReport = isAdmin
            ? moment()
            : getDefaultContentDateAvailableReport();

          const oldestContentDate = moment(
            first(
              contentData
                .map((content) => {
                  return {
                    ...content,
                    originalDate: content.originalDate || content.createdDate,
                  };
                })
                .sort((a, b) => {
                  return (
                    moment(a.originalDate).valueOf() -
                    moment(b.originalDate).valueOf()
                  );
                })
            )?.originalDate
          );
          if (oldestContentDate > dateAvailableReport) {
            setIsValid(false);
            setIsLoading(false);
            return;
          }

          setDateStrings([
            moment(oldestContentDate).format(DATE_FORMAT),
            dateAvailableReport.format(DATE_FORMAT),
          ]);

          setIsValid(true);

          //Logic for SendInBlue data
          const campaignContents = contentData.filter(
            (content) => content.sibCampaignId
          );
          if (campaignContents.length) {
            await Promise.all(
              campaignContents.map(async (content) => {
                const reportData = await getSendInBlueReport({
                  id: content.sibCampaignId,
                  contentId: content.id,
                });
                setSendInBlueReport((report) => {
                  const updating = { ...report };
                  updating[content.sibCampaignId] = reportData;
                  return updating;
                });
              })
            );
          }
        } else {
          setError(true);
        }
      } catch (error) {
        message.error("Something went wrong");
        console.error(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [contentIds, isAdmin, isInitiated, setDateStrings]);

  const summaryReport = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const impressionsReport = useMemo(() => {
    return reviseReport(reports[1]);
  }, [reports]);

  const deepClicksReport = useMemo(() => {
    return reviseReport(reports[2]);
  }, [reports]);

  const deepLinkClicksReport = useMemo(() => {
    return reviseReport(reports[3]);
  }, [reports]);

  const filterContentData = useMemo(() => {
    if (!contentData || !dateStrings || !dateStrings[0] || !dateStrings[1]) {
      return [];
    }
    return contentData.filter((content) =>
      moment(content.originalDate || content.createdDate).isBetween(
        moment(dateStrings[0], DATE_FORMAT),
        moment(dateStrings[1], DATE_FORMAT).add(1, "days")
      )
    );
  }, [contentData, dateStrings]);

  const contextValue = useMemo(() => {
    return {
      summaryReport,
      contentData: filterContentData,
      canViewContentData: canViewContentIds.reduce((result, val) => {
        result[val] = true;
        return result;
      }, {}),
      deepClicksReport,
      deepLinkClicksReport,
      impressionsReport,
      dateStrings,
      sendInBlueReport,
      emailEventsMap,
      setEmailEventCount,
    };
  }, [
    summaryReport,
    filterContentData,
    deepClicksReport,
    deepLinkClicksReport,
    impressionsReport,
    dateStrings,
    sendInBlueReport,
    emailEventsMap,
    setEmailEventCount,
    canViewContentIds,
  ]);

  if (error) return <Result status="404" title="Something went wrong" />;

  if (isLoading)
    return (
      <div className="flex items-center justify-center">
        <Spin />
      </div>
    );

  if (!isValid) return <Result title="The report is unavailable yet!" />;

  return (
    <BulkContentViewsProvider value={contextValue}>
      <Spin spinning={isLoading}>
        <ContentSummary />
      </Spin>
    </BulkContentViewsProvider>
  );
};

ContentViews.propTypes = {};

export default ContentViews;
