import { Empty } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart as AntBarChart,
} from "recharts";
import { getDimensionColorByIndex } from "utils/colorUtils";

const BarChart = ({ bars, data, xAxisDataKey }) => {
  const computedBars = useMemo(() => {
    return (bars || []).map((header, index) => ({
      ...header,
      color: getDimensionColorByIndex(index),
    }));
  }, [bars]);

  if (isEmpty(data)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <ResponsiveContainer height={320}>
      <AntBarChart
        data={data}
        margin={{
          top: 0,
          right: 16,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisDataKey} />
        <YAxis />
        <Tooltip />
        <Legend iconType="circle" iconSize={10} />
        {computedBars.map((bar) => (
          <Bar
            name={bar.name}
            key={bar.dataKey}
            dataKey={bar.dataKey}
            fill={bar.color}
          />
        ))}
      </AntBarChart>
    </ResponsiveContainer>
  );
};

BarChart.propTypes = {};

export default BarChart;
