import React, { useMemo } from "react";
import ChartTitle from "components/ChartTitle";
import { Card, Col, Empty, Row, Statistic, Tooltip } from "antd";
import { GRID_GUTTER, HIDE_VISITORS, LABEL_INACTIVE } from "constants/ui";
import {
  EVENT_CLOSE_ITEM,
  EVENT_MAXIMIZE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_PLUG_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import {
  METRIC_EVENT_COUNT,
  METRIC_PAGE_VIEWS,
  METRIC_TOTAL_USERS,
} from "constants/metrics";
import Scorecard from "components/charts/Scorecard";
import { get, isEmpty, sum } from "lodash";
import {
  DIMENSION_DATE,
  DIMENSION_DEVICETYPE,
  DIMENSION_EVENT_NAME,
  DIMENSION_TIMESTAMP,
} from "constants/dimensions";
import { reviseTimeSeriesData } from "services/gaService";
import LineChart from "components/charts/LineChart";
import TooltipText from "components/TooltipText";
import { useWidgetsContext } from "../../PlugitProvider";
import {
  DIMENSION_DURATION,
  DIMENSION_ITEM_CATEGORY,
} from "constants/customDimensions";
import { TAG_CONTENT_TAGS, TAG_LIST, TAG_LISTSET } from "constants/contentTags";
import { SOURCE_PLUGIT } from "constants/contentSources";
import moment from "moment";
import BarChart from "components/charts/BarChart";
import {
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  BarChart as AntBarChart,
} from "recharts";
import { getDimensionColorByIndex } from "utils/colorUtils";

const OverviewSection = ({
  domain,
  widgetImpressionsReportData,
  OverviewReportData,
  contentViewsReportData,
  plugitOverviewReportData,
  visitorOvertimeReportData,
  deviceTypesReportData,
  dateStrings,
  usersReportData,
  DurationReportData,
}) => {

  const widgetImpressionTimeSeriesData = useMemo(() => {
    if (isEmpty(widgetImpressionsReportData)) return [];

    const rowsGroupedByDate = widgetImpressionsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};
        result[date].date = date;
        result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
          result[date][EVENT_VIEW_ITEM_IMPRESSION],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION],
    });
  }, [dateStrings, widgetImpressionsReportData]);

  const dockAvgOpenTime = useMemo(() => {
    if (isEmpty(DurationReportData)) return 0;
    const duration = Math.ceil(DurationReportData.rows.reduce((result, current) => {
      return result + parseInt(current[DIMENSION_DURATION]); 
    }, 0) / DurationReportData.rows.length);
    const durationObj = moment.duration(duration);
    const minutes = durationObj.minutes();
    const seconds = durationObj.seconds();
    return duration> 0?( minutes ? `${minutes}m ${seconds}s` : `${seconds}s`) : 0;
  }, [DurationReportData]);


  const usersTimeSeriesData = useMemo(() => {
    if (isEmpty(usersReportData)) return [];

    const rowsGroupedByDate = usersReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_TOTAL_USERS] = sum([
        result[date][METRIC_TOTAL_USERS],
        current[METRIC_TOTAL_USERS],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [METRIC_TOTAL_USERS],
    });
  }, [dateStrings, usersReportData]);

  // NOTE: because GA doesn't provide get OR and AND filters in parallel, so we have to merge data here
  const widgetImpressionWithUsersTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(widgetImpressionTimeSeriesData) && isEmpty(usersTimeSeriesData))
      return [];

    const dataLength =
      widgetImpressionTimeSeriesData.length || usersTimeSeriesData.length;

    return Array(dataLength)
      .fill()
      .map((_, index) => {
        const widgetItem = widgetImpressionTimeSeriesData[index] || {};
        const userItem = usersTimeSeriesData[index] || {};
        return {
          ...widgetItem,
          [METRIC_TOTAL_USERS]: userItem[METRIC_TOTAL_USERS] || 0,
        };
      });
  }, [widgetImpressionTimeSeriesData, usersTimeSeriesData]);

  const widgetImpressionCount = useMemo(() => {
    if (isEmpty(widgetImpressionTimeSeriesData)) return 0;

    return sum(
      widgetImpressionTimeSeriesData.map(
        (item) => item[EVENT_VIEW_ITEM_IMPRESSION]
      )
    );
  }, [widgetImpressionTimeSeriesData]);
  
 


  // const deviceTypesSeriesReportData = [
  //   { [DIMENSION_DEVICETYPE]: "Desktop", [METRIC_TOTAL_USERS]: 1200 },
  //   { [DIMENSION_DEVICETYPE]: "Mobile", [METRIC_TOTAL_USERS]: 800 },
  //   { [DIMENSION_DEVICETYPE]: "Tablet", [METRIC_TOTAL_USERS]: 300 },
  //   { [DIMENSION_DEVICETYPE]: "Smart TV", [METRIC_TOTAL_USERS]: 150 },
  //   { [DIMENSION_DEVICETYPE]: "Wearable", [METRIC_TOTAL_USERS]: 50 },
  //   { [DIMENSION_DEVICETYPE]: "Game Console", [METRIC_TOTAL_USERS]: 100 },
  //   { [DIMENSION_DEVICETYPE]: "E-Reader", [METRIC_TOTAL_USERS]: 20 },
  //   { [DIMENSION_DEVICETYPE]: "Smart Speaker", [METRIC_TOTAL_USERS]: 60 },
  //   { [DIMENSION_DEVICETYPE]: "Feature Phone", [METRIC_TOTAL_USERS]: 10 },
  //   { [DIMENSION_DEVICETYPE]: "Other", [METRIC_TOTAL_USERS]: 5 },
  // ];

  const contentActiveViewCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const dockImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            [SOURCE_PLUGIT].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);
  const dockOpenedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;
    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_MAXIMIZE_ITEM &&
            [SOURCE_PLUGIT].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  // const dockAvgOpenTime = useMemo(() => {
  //   if (isEmpty(plugitOverviewReportData)) return 0;
  //   const durations = plugitOverviewReportData.rows
  //     .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_MINIMIZE_ITEM)
  //     .map((row) => parseInt(row[DIMENSION_DURATION]))
  //     .filter((duration) => !isNaN(duration));
  //   return Math.ceil(sum(durations) / durations.length);
  // }, [plugitOverviewReportData]);

  const contentImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentClickCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const stackImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            [TAG_LISTSET, TAG_LIST].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const stackViewCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
            [TAG_LISTSET, TAG_LIST].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentAddedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_PLUG_ITEM &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentRemovedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_UNPLUG_ITEM &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);



  return (
    <>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>plugit · Trends</ChartTitle>
          <Card size="small mb-8">
          <LineChart
              xAxisDataKey="date"
              data={widgetImpressionWithUsersTrendTimeSeriesData}
              lines={[
                {
                  name: "Ad Impressions",
                  dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                },
                ...(!HIDE_VISITORS
                  ? [
                      {
                        name: "Visitors",
                        dataKey: METRIC_TOTAL_USERS,
                      },
                    ]
                  : []),
              ]}
            />
          </Card>
          
        </Col>
        <Col xs={24} lg={12}>
          {/* <ChartTitle>Vistors by City</ChartTitle>
          <Card size="small mb-8">
            <LineChart
              xAxisDataKey="date"
              data={[] || "widgetImpressionWithUsersTrendTimeSeriesData"}
              lines={[
                {
                  name: "Ad Impressions",
                  dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                },

                {
                  name: "Visitors",
                  dataKey: METRIC_TOTAL_USERS,
                },
              ]}
            />
          </Card> */}

          <ChartTitle>Plugit Dock</ChartTitle>
          <Card className="mb-8" size="small">
            <Row>
              <Col span={8} lg={8} className="mb-8 lg:mb-0">
                <Statistic
                  title="Impressions"
                  value={dockImpressionCount || LABEL_INACTIVE}
                />
              </Col>

              <Col span={8} lg={8} className="mb-8 lg:mb-0">
                <Statistic
                  title="Open"
                  value={dockOpenedCount || LABEL_INACTIVE}
                />
              </Col>
              <Col span={8} lg={8} className="mb-8 lg:mb-0">
                <Statistic
                  title="Avg. Time Open"
                  value={dockAvgOpenTime || LABEL_INACTIVE}
                />
              </Col>
            </Row>
          </Card>

          <ChartTitle>Content</ChartTitle>
          <Row gutter={GRID_GUTTER} className="mb-8">
            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Impressions"
                  value={contentImpressionCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Views"
                  value={contentActiveViewCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Clicks"
                  value={contentClickCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
          <ChartTitle>Stack</ChartTitle>
          <Row gutter={GRID_GUTTER} className="mb-8">
            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Impressions"
                  value={stackImpressionCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Views"
                  value={stackViewCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={GRID_GUTTER}>
            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Content Added"
                  value={contentAddedCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Content Removed"
                  value={contentRemovedCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8 lg:mb-0"></Col>

        <Col xs={24} lg={12}></Col>
      </Row>
    </>
  );
};

OverviewSection.propTypes = {};

export default OverviewSection;
