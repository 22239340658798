import React, { useCallback, useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
} from "constants/dimensions";
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_CATEGORY2,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_URL,
  DIMENSION_ITEM_URL1,
  DIMENSION_ITEM_URL2,
  DIMENSION_ITEM_URL3,
  DIMENSION_ITEM_URL4,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
} from "constants/customDimensions";
import { get, isEmpty, isArray, sum } from "lodash";
import { Spin, Result, message } from "antd";
import {
  getBatchReports,
  getContent,
  getPlugiloReport,
  getSendInBlueReport,
  reviseReport,
} from "services/gaService";
import { buildInListFilter } from "services/gaDimensionsService";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import { useParams } from "react-router";
import {
  SOURCE_EMAIL,
  SOURCE_LIGHTWEIGHT,
  SOURCE_MOBILE,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import useQueryString from "hooks/useQueryString";
import ContentViewsProvider from "./ContentViewsContext";
import Summary from "./components/Summary";
import {
  PARAM_CONTENT_TYPE,
  PARAM_MERGED_IDS,
} from "constants/queryStringParams";
import moment from "moment";
import {
  DATE_FORMAT,
  getDefaultContentDateAvailableReport,
  MAX_DATE_RANGE_IN_DAYS,
} from "constants/default";
import Publications from "./components/Publications";
import Impressions from "./components/Impressions";
import ActiveViews from "./components/ActiveViews";
import Clicks from "./components/Clicks";
import LinkClicks from "./components/LinkClicks";
import DeepClicks from "./components/DeepClicks";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { clientEmailSourcesMap } from "./emailSourcesMap";
import { alertUnknownError } from "services/notificationService";
import { useAppContext } from "contexts/AppContextProvider";
import DailyTraffic from "./components/DailyTraffic";

const buildFilters = (filters) => {
  return {
    andGroup: {
      expressions: [...(filters || [])],
    },
  };
};

const ContentViews = () => {
  const [isInitiated, setIsInitiated] = useState(false);
  const [reports, setReports] = useState([]);
  const [contentData, setContentData] = useState({});
  const [plugiloReport, setPlugiloReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [sendInBlueReport, setSendInBlueReport] = useState();
  const [useSendInBlueReport, setUseSendInBlueReport] = useState();
  const [emailSourcesMap, setEmailSourcesMap] = useState([]);
  const [clickSourcesMap, setClickSourcesMap] = useState([]);
  const [deepClickSourcesMap, setDeepClickSourcesMap] = useState([]);
  const [emailEventsMap, setEmailEventsMap] = useState({});
  const [timeSeriesEventsMap, setTimeSeriesEventsMap] = useState({});
  const { isAdmin } = useAppContext();
  const { dateStrings, setDateStrings } = useReportContext();
  const { id: contentId } = useParams();
  const {
    [PARAM_CONTENT_TYPE]: contentType,
    [PARAM_MERGED_IDS]: mergeIds,
    start,
    end,
    pk,
  } = useQueryString();
  const [error, setError] = useState();

  const setEmailEventCount = useCallback((sourceName, eventType, hasEvent) => {
    setEmailEventsMap((oldValue) => {
      return {
        ...oldValue,
        [sourceName]: { ...oldValue[sourceName], [eventType]: hasEvent },
      };
    });
  }, []);

  const setTimeSeriesData = useCallback((type, data) => {
    setTimeSeriesEventsMap((oldValue) => {
      return {
        ...oldValue,
        [type]: data,
      };
    });
  }, []);

  const contentIds = useMemo(() => {
    return [contentId, ...(isArray(mergeIds) ? mergeIds : [mergeIds])].filter(
      (id) => id
    );
  }, [mergeIds, contentId]);

  const reportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_CATEGORY2,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
            ignoreSources: [3],
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_ITEM_ID, contentIds),
          buildInListFilter(DIMENSION_ITEM_SOURCE, [
            SOURCE_PLUGILO,
            SOURCE_EMAIL,
            SOURCE_WIDGET,
            SOURCE_LIGHTWEIGHT,
            SOURCE_PLUGIT,
            SOURCE_MOBILE,
          ]),
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_DETAILS,
            EVENT_VIEW_ITEM_EMAIL_DETAILS,
            EVENT_VIEW_ITEM_IMPRESSION,
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_LINK_CLICKED,
          ]),
        ]),
      },
      // IMPRESSIONS
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_HOSTNAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_ITEM_ID, contentIds),
          buildInListFilter(DIMENSION_ITEM_SOURCE, [
            SOURCE_PLUGILO,
            SOURCE_WIDGET,
            SOURCE_LIGHTWEIGHT,
            SOURCE_PLUGIT,
            SOURCE_MOBILE,
          ]),
          buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_VIEW_ITEM_IMPRESSION]),
        ]),
      },
      // DEEP CLICKS
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_URL,
          },
          {
            name: DIMENSION_ITEM_CATEGORY2,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
            ignoreSources: [3],
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_LINK_CLICKED,
          ]),
          buildInListFilter(DIMENSION_ITEM_SOURCE_ID, contentIds),
        ]),
      },
      // DEEP LINK CLICKS
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_URL,
          },
          {
            name: DIMENSION_ITEM_URL1,
          },
          {
            name: DIMENSION_ITEM_URL2,
          },
          {
            name: DIMENSION_ITEM_URL3,
          },
          {
            name: DIMENSION_ITEM_URL4,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_LINK_CLICKED,
          ]),
          buildInListFilter(DIMENSION_ITEM_SOURCE_ID, contentIds),
        ]),
      },
    ];
  }, [contentIds, dateStrings]);

  useEffect(() => {
    if (!reportRequests || !isInitiated || !isValid) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests, contentId, pk);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests, isInitiated, isValid]);

  const handleEmailPublicationSources = (rawSources) => {
    if (isEmpty(rawSources)) return;

    const sourcesMap = rawSources.reduce((prev, current) => {
      const publicationName = current.name.toLowerCase();
      const refinedPublicationKey = current.id || publicationName;
      if (!prev[refinedPublicationKey])
        prev[refinedPublicationKey] = {
          key: refinedPublicationKey,
          displayName: current.displayName,
          names: [],
          sent: 0,
          triggerThreshold: current.triggerThreshold,
        };

      prev[refinedPublicationKey].names.push(publicationName);
      prev[refinedPublicationKey].sent =
        prev[refinedPublicationKey].sent + current.sent;

      // if (publicationName === "dcidailyhighlight") {
      //   prev[refinedPublicationKey].triggerThreshold = 20000;
      // }

      return prev;
    }, {});

    setEmailSourcesMap(sourcesMap);
  };

  const handleClickSources = (rawSources) => {
    if (isEmpty(rawSources)) return;

    const sourcesMap = rawSources.reduce((prev, current) => {
      const publicationName = current.name.toLowerCase();
      if (!prev[publicationName])
        prev[publicationName] = {
          key: publicationName,
          displayName: current.displayName,
          names: [],
          triggerThreshold: current.triggerThreshold,
        };

      prev[publicationName].names.push(publicationName);

      // if (publicationName === "proconnectweekly") {
      //   prev[publicationName].triggerThreshold = 0;
      // }

      return prev;
    }, {});

    setClickSourcesMap(sourcesMap);
  };

  const handleDeepClickSources = (rawSources) => {
    if (isEmpty(rawSources)) return;

    const sourcesMap = rawSources.reduce((prev, current) => {
      const publicationName = current.name.toLowerCase();
      if (!prev[publicationName])
        prev[publicationName] = {
          key: publicationName,
          displayName: current.displayName,
          names: [],
          triggerThreshold: current.triggerThreshold,
        };

      prev[publicationName].names.push(publicationName);
      return prev;
    }, {});

    setDeepClickSourcesMap(sourcesMap);
  };

  useEffect(() => {
    if (!contentId) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const contentData = await getContent(contentId, pk);
        const pReport = await getPlugiloReport(contentId, pk);
        if (contentData) {
          setContentData(contentData);
          const createdDate = moment(contentData.originalDate);
          const dateAvailableReport = isAdmin
            ? moment()
            : getDefaultContentDateAvailableReport();

          if (createdDate > dateAvailableReport) {
            setIsValid(false);
            setIsLoading(false);
            return;
          }

          const startDate = moment(start);
          const endDate = moment(end);
          const canUseManualDateRange =
            startDate.isValid() &&
            endDate.isValid() &&
            startDate <= endDate &&
            endDate <= moment() &&
            endDate.diff(startDate, "days") > MAX_DATE_RANGE_IN_DAYS;
          if (canUseManualDateRange) {
            setDateStrings([start, end]);
          } else {
            const nowEndDate = moment();
            const maxEndDate = moment(contentData.originalDate).add(
              MAX_DATE_RANGE_IN_DAYS,
              "days"
            );
            const finalEndDate =
              maxEndDate > nowEndDate ? nowEndDate : maxEndDate;

            setDateStrings([
              moment(contentData.originalDate).format(DATE_FORMAT),
              finalEndDate.format(DATE_FORMAT),
            ]);
          }

          setIsInitiated(true);
          setIsValid(true);
        } else {
          setError(true);
        }

        setPlugiloReport(pReport);
        handleEmailPublicationSources(pReport?.statistics?.emailPublications);
        handleClickSources(pReport?.statistics?.clicks);
        handleDeepClickSources(pReport?.statistics?.deepClicks);

        // Logic for SendInBlue data
        if (contentData?.sibCampaignId) {
          const reportData = await getSendInBlueReport({
            id: contentData.sibCampaignId,
            contentId: contentId,
          });

          setUseSendInBlueReport(true);
          setSendInBlueReport(reportData);

          const linksStats = get(sendInBlueReport, "statistics.linksStats", {});
          const clickCount = sum(
            Object.keys(linksStats).map((key) => linksStats[key] || 0)
          );
          setEmailEventCount(
            clientEmailSourcesMap.PremiumEmail.key,
            "deepClicks",
            clickCount
          );
        }
      } catch (error) {
        message.error("Something went wrong");
        console.error(error);
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const summaryReport = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const impressionsReport = useMemo(() => {
    return reviseReport(reports[1]);
  }, [reports]);

  const deepClicksReport = useMemo(() => {
    return reviseReport(reports[2]);
  }, [reports]);

  const deepLinkClicksReport = useMemo(() => {
    return reviseReport(reports[3]);
  }, [reports]);

  const contextValue = useMemo(() => {
    return {
      summaryReport,
      contentData,
      plugiloReport,
      deepClicksReport,
      deepLinkClicksReport,
      impressionsReport,
      dateStrings,
      emailSourcesMap,
      clickSourcesMap,
      deepClickSourcesMap,
      sendInBlueReport,
      useSendInBlueReport,
      emailEventsMap,
      setEmailEventCount,
      timeSeriesEventsMap,
      setTimeSeriesData,
    };
  }, [
    summaryReport,
    contentData,
    plugiloReport,
    deepClicksReport,
    deepLinkClicksReport,
    impressionsReport,
    dateStrings,
    emailSourcesMap,
    clickSourcesMap,
    deepClickSourcesMap,
    sendInBlueReport,
    useSendInBlueReport,
    emailEventsMap,
    setEmailEventCount,
    timeSeriesEventsMap,
    setTimeSeriesData,
  ]);

  if (error) return <Result status="404" title="Something went wrong" />;

  if (isLoading)
    return (
      <div className="flex items-center justify-center">
        <Spin />
      </div>
    );

  if (!isValid) return <Result title="The report is unavailable yet!" />;

  return (
    <ContentViewsProvider value={contextValue}>
      <Spin spinning={isLoading}>
        <Summary />
        <Publications />
        <Impressions />
        <ActiveViews />
        <Clicks />
        <DeepClicks />
        <LinkClicks />
        <DailyTraffic />
      </Spin>
    </ContentViewsProvider>
  );
};

ContentViews.propTypes = {};

export default ContentViews;
