import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, sum, orderBy, get } from "lodash";
import { useContentViewsContext } from "../../BulkContentViewsContext";
import { Card, Space, Table, Typography } from "antd";
import TableChart from "components/charts/TableChart";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { formatNumber } from "services/numberService";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import {
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_ID,
} from "constants/customDimensions";
import { DIMENSION_EVENT_NAME } from "constants/dimensions";
import { v4 as uuid } from "uuid";
import { DATE_FORMAT_PRINT } from "constants/default";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import {
  SOURCE_EMAIL,
  SOURCE_LIGHTWEIGHT,
  SOURCE_MOBILE,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import ContentLink from "components/ContentLink";
import Icon from "components/Icon";
import LinkToDetails from "../LinkToDetails";
import { getTextByKey } from "services/textService";

function ContentSummary() {
  const {
    contentData,
    canViewContentData,
    summaryReport,
    sendInBlueReport,
    deepClicksReport,
  } = useContentViewsContext();
  const [tableData, setTableData] = useState([]);

  const getViewCount = useCallback(
    (contentId) => {
      if (isEmpty(summaryReport)) return 0;
      const contentIds = Array.isArray(contentId) ? contentId : [contentId];
      let count = 0;
      // plugilo/widget view count
      let filteredrows = summaryReport.rows.filter(
        (row) =>
          row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
          [
            SOURCE_PLUGILO,
            SOURCE_WIDGET,
            SOURCE_EMAIL,
            SOURCE_LIGHTWEIGHT,
            SOURCE_MOBILE,
            SOURCE_PLUGIT,
          ].includes(row[DIMENSION_ITEM_SOURCE]) &&
          contentIds.includes(row[DIMENSION_ITEM_ID])
      );
      count += sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));

      // email view count
      filteredrows = summaryReport.rows.filter(
        (row) =>
          row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_EMAIL_DETAILS &&
          [SOURCE_EMAIL].includes(row[DIMENSION_ITEM_SOURCE]) &&
          contentIds.includes(row[DIMENSION_ITEM_ID])
      );
      count += sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));

      // email impression view count
      filteredrows = summaryReport.rows.filter(
        (row) =>
          row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
          row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL &&
          contentIds.includes(row[DIMENSION_ITEM_ID])
      );
      count += sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));

      return count;
    },
    [summaryReport]
  );
  const getClicksOnCard = useCallback(
    (contentId) => {
      if (isEmpty(summaryReport)) return 0;
      const contentIds = Array.isArray(contentId) ? contentId : [contentId];
      let count = 0;
      // plugilo/widget/email clicks count
      let filteredrows = summaryReport.rows.filter(
        (row) =>
          row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
          [
            SOURCE_PLUGILO,
            SOURCE_WIDGET,
            SOURCE_EMAIL,
            SOURCE_LIGHTWEIGHT,
            SOURCE_MOBILE,
            SOURCE_PLUGIT,
          ].includes(row[DIMENSION_ITEM_SOURCE]) &&
          contentIds.includes(row[DIMENSION_ITEM_ID])
      );
      count += sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));

      return count;
    },
    [summaryReport]
  );

  const getDeepClicks = useCallback(
    (contentId, sibCampaignId) => {
      if (isEmpty(deepClicksReport)) return 0;
      const contentIds = Array.isArray(contentId) ? contentId : [contentId];
      let count = 0;
      // plugilo/widget/email clicks count
      let filteredrows = deepClicksReport.rows.filter(
        (row) =>
          row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_LINK_CLICKED &&
          [
            SOURCE_PLUGILO,
            SOURCE_WIDGET,
            SOURCE_LIGHTWEIGHT,
            SOURCE_MOBILE,
            SOURCE_PLUGIT,
          ].includes(row[DIMENSION_ITEM_SOURCE]) &&
          contentIds.includes(row[DIMENSION_ITEM_ID])
      );
      count += sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));

      if (sibCampaignId) {
        const linksStats = get(
          sendInBlueReport,
          `${sibCampaignId}.statistics.linksStats`,
          {}
        );
        count += sum(
          Object.keys(linksStats).map((key) => linksStats[key] || 0)
        );
      } else {
        if (!isEmpty(deepClicksReport)) {
          filteredrows = deepClicksReport.rows.filter(
            (row) =>
              row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL &&
              contentIds.includes(row[DIMENSION_ITEM_SOURCE_ID])
          );
          count += sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
        }
      }

      return count;
    },
    [deepClicksReport, sendInBlueReport]
  );

  const summaryCount = useMemo(() => {
    const summary = {};
    if (isEmpty(contentData)) return summary;
    for (const content of contentData) {
      summary[content.id] = {
        viewCount: getViewCount([
          content.id,
          ...(content.mergedContentIds || []),
        ]),
        clicksOnCard: getClicksOnCard([
          content.id,
          ...(content.mergedContentIds || []),
        ]),
        deepClicks: getDeepClicks(
          [content.id, ...(content.mergedContentIds || [])],
          content.sibCampaignId
        ),
      };
    }
    return summary;
  }, [contentData, getClicksOnCard, getViewCount, getDeepClicks]);

  useEffect(() => {
    const contents = [];
    if (!contentData || !contentData.length) return;
    for (const content of contentData) {
      const contentItem = {
        id: content.id,
        name: content.title || content.name,
        description: content.description,
        tags: content.tags,
        originalDate: content.originalDate || content.createdDate,
        summaryCount: get(summaryCount, content.id, {}),
        tag: get(content, "tags[0]"),
        ownerDomain: content.ownerDomain,
      };
      contents.push(contentItem);
    }
    setTableData(contents);
  }, [contentData, summaryCount]);

  return (
    <section>
      <Card size="small">
        <TableChart
          pageSize={30}
          rowKey="id"
          dataSource={tableData}
          columns={[
            {
              title: "Date",
              dataIndex: "originalDate",
              key: "originalDate",
              width: 130,
              render: (value) => {
                return moment(value).format(DATE_FORMAT_PRINT);
              },
              // onCell: ()=>{ return {
              //   onClick: (ev) => {
              //       console.log('xxx');
              //   },
              // }
              // }
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              render: (value, record) => (
                <Space direction="horizontal">
                  <div className="print:hidden">
                    <ContentLink
                      id={record.id}
                      name={record.name}
                      tag={record.tag}
                      ownerDomain={record.ownerDomain}
                    >
                      <Icon name="arrow-up-right-from-square" />
                    </ContentLink>
                  </div>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2, expandable: true }}
                  >
                    {record.name || record.description}
                  </Typography.Paragraph>
                </Space>
              ),
            },

            {
              title: "Active views",
              dataIndex: "summaryCount.viewCount",
              key: "summaryCount.viewCount",
              width: 100,
              align: "right",
              render: (value, record) =>
                canViewContentData[record.id] ? (
                  <LinkToDetails tag={record.tag} id={record.id}>
                    {formatNumber(get(record, "summaryCount.viewCount", 0))}
                  </LinkToDetails>
                ) : (
                  <span>N/A</span>
                ),
            },
            {
              title: "Click on card",
              dataIndex: "summaryCount.clicksOnCard",
              key: "summaryCount.clicksOnCard",
              width: 100,
              align: "right",
              render: (value, record) =>
                canViewContentData[record.id] ? (
                  <LinkToDetails tag={record.tag} id={record.id}>
                    {formatNumber(get(record, "summaryCount.clicksOnCard", 0))}
                  </LinkToDetails>
                ) : (
                  <span>N/A</span>
                ),
            },
            {
              title: getTextByKey("Plugilo.Analytics.ClickOuts"),
              dataIndex: "summaryCount.deepClicks",
              key: "summaryCount.deepClicks",
              width: 100,
              align: "right",
              render: (value, record) =>
                canViewContentData[record.id] ? (
                  <LinkToDetails tag={record.tag} id={record.id}>
                    {formatNumber(get(record, "summaryCount.deepClicks", 0))}
                  </LinkToDetails>
                ) : (
                  <span>N/A</span>
                ),
            },
          ]}
        />
      </Card>
    </section>
  );
}

ContentSummary.propTypes = {};

export default ContentSummary;
