import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  METRIC_EVENT_COUNT,
} from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
} from "constants/dimensions";
import {
  DIMENSION_INFOBOARD_BRAND,
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
} from "constants/customDimensions";
import { get, isEmpty,  sum, uniqBy } from "lodash";
import { Col, Row, Spin, Select, Divider, Space } from "antd";
import { GRID_GUTTER } from "constants/ui";
import {
  reviseReport,
  reviseTimeSeriesData,
  getBatchReports,
} from "services/gaService";
import {
  FILTER_ITEM_SOURCE_PLUGIT,
  buildStringFilter,
  buildInListFilter,
} from "services/gaDimensionsService";
import {
  EVENT_MAXIMIZE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_PLUG_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import Favicon from "components/Favicon";
import { useParams } from "react-router";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import OverviewSection from "./components/OverviewSection";
import TopViewsSection from "./components/TopViewsSection";
import useSetQueryString from "hooks/useSetQueryString";
import {
  PARAM_WIDGET_PARTNER,
  PARAM_WIDGET_ITEM_OWNER,
  PARAM_WIDGET_SOURCE,
  PARAM_WIDGET_STREAM,
  PARAM_WIDGET_ITEM_ID,
} from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";
import { useAppContext } from "contexts/AppContextProvider";
import { alertUnknownError } from "services/notificationService";
import PlugitProvider from "./PlugitProvider";
import {
  TAG_BOOKMARK,
  TAG_FACEBOOK,
  TAG_FEED,
  TAG_HIGHLIGHT,
  TAG_INSTAGRAM,
  TAG_LINKEDIN,
  TAG_PINTEREST,
  TAG_PRODUCT,
  TAG_RSS,
  TAG_TWITTER,
  TAG_YOUTUBE,
} from "constants/contentTags";
import { SOURCE_PLUGIT } from "constants/contentSources";
import DailyTrafficSection from "./components/DailyTrafficSection";

const defaultOrderBys = [
  {
    desc: true,
    metric: {
      metricName: METRIC_EVENT_COUNT,
    },
  },
];

const buildFilters = (widgetIds, filters, notFilters) => {
  const expressions = [FILTER_ITEM_SOURCE_PLUGIT];
  if (widgetIds && widgetIds.length) {
    expressions.push(buildInListFilter(DIMENSION_ITEM_SOURCE_ID, widgetIds));
  }
  expressions.push(...(filters || []));
  return {
    andGroup: {
      expressions: expressions,
      // notExpressions: notFilters,
    },
  };
};

const domainsHaveProductCardRight = [
  "krups.de",
  "sonicwall.com",
  "vnexpress.net",
  "fptplay.vn",
  "bluechip.de",
  "comteam.de",
];
const ignoreHostNames = [];
const Shops = () => {
  const [setParam, _, removeParam] = useSetQueryString();
  const {
    [PARAM_WIDGET_PARTNER]: paramWidgetPartner,
    [PARAM_WIDGET_SOURCE]: paramWidgetSource,
    [PARAM_WIDGET_STREAM]: paramWidgetStream,
    [PARAM_WIDGET_ITEM_OWNER]: paramWidgetItemOwner,
    [PARAM_WIDGET_ITEM_ID]: paramWidgetItemId,
  } = useQueryString();

  const [reports01, setReports01] = useState([]);
  const [reports02, setReports02] = useState([]);
  const [reports03, setReports03] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const {    
    dateStrings,
    lookbackWindow
  } = useReportContext();
  const [selectedSourceId, setSelectedSourceId] = useState(paramWidgetSource);
  const [selectedSourceStreamId, setSelectedSourceStreamId] =
    useState(paramWidgetStream);
  const [selectedPartner, setSelectedPartner] = useState(paramWidgetPartner);
  const [selectedItemOwner, setSelectedItemOwner] =
    useState(paramWidgetItemOwner);
  const [selectedItemId, setSelectedItemId] = useState(paramWidgetItemId);
  // const [availableContents, setAvailableContents] = useState([]);
  const [widgetSources, setWidgetSources] = useState([]);
  const [topContentAddedToStackReportData, setTopContentAddedToStackReportData] = useState([]);
  const { id: domain } = useParams();
  const hasProductRight = domainsHaveProductCardRight.includes(domain);
  const { isPrintMode } = useAppContext();


  const reportRequests01 = useMemo(() => {
    if (
      !dateStrings ||
      !dateStrings[0] ||
      !dateStrings[1] 
    )
      return;
    
    return [
      // 0: General Filters
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_INFOBOARD_BRAND,
          },
         
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([], [buildStringFilter(DIMENSION_ITEM_BRAND, domain)]),
      },
      
    ];
  }, [dateStrings, domain]);

  const reportRequests02 = useMemo(() => {
    if (
      !dateStrings ||
      !dateStrings[0] ||
      !dateStrings[1] 
    )
      return;
    const commonFilters = [];

    if(domain){
      commonFilters.push(buildStringFilter(DIMENSION_ITEM_BRAND, domain));
    }
    if (selectedPartner)
      commonFilters.push(
        buildStringFilter(
          DIMENSION_INFOBOARD_BRAND,
          selectedPartner
        )
      );

  

    if (selectedItemOwner)
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_BRAND, selectedItemOwner)
      );
    // if (selectedItemId)
    //   commonFilters.push(buildStringFilter(DIMENSION_ITEM_ID, selectedItemId));

    return [
     
     
      // 0: Content Overview
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(
          [],
          [
            ...commonFilters,
            buildInListFilter(DIMENSION_ITEM_CATEGORY, [
              TAG_BOOKMARK,
              TAG_HIGHLIGHT,
              TAG_PRODUCT,
              TAG_RSS,
              TAG_FEED,
              TAG_TWITTER,
              TAG_FACEBOOK,
              TAG_YOUTUBE,
              TAG_LINKEDIN,
              TAG_PINTEREST,
              TAG_INSTAGRAM,
            ]),
            buildInListFilter(DIMENSION_EVENT_NAME, [
              EVENT_VIEW_ITEM_IMPRESSION,
              EVENT_VIEW_ITEM_DETAILS,
              EVENT_VIEW_ITEM_CLICKED,
              EVENT_PLUG_ITEM,
              EVENT_UNPLUG_ITEM,           
            ]),
          ]
        ),
      },
      // 1 : Top content added to stack
      // {
      //   dateRanges: [
      //     {
      //       startDate: dateStrings[0],
      //       endDate: dateStrings[1],
      //     },
      //   ],
      //   dimensions: [
      //     {
      //       name: DIMENSION_ITEM_ID,
      //     },        
      //   ],
      //   metrics: [         
      //     {
      //       name: METRIC_EVENT_COUNT,
      //     },
      //   ],
      //   dimensionFilter: buildFilters([], [...commonFilters, buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_PLUG_ITEM])]),
      //   order_by: [
      //     [
      //         1,
      //         "desc"
      //     ]
      // ],
      // limit:10
      // }
    ];
  }, [ dateStrings, selectedPartner, selectedItemOwner, domain]);

  

  const resetData = () => {
    setReports01([]);
    setReports02([]);
  };
  const loadFilterReports = useCallback(async () => {
    if (!reportRequests01) return;
    // setIsLoading(true);
    try {
      const data = await getBatchReports(reportRequests01);
      setReports01(get(data, "reports", []));
    } catch (error) {
      alertUnknownError();
      resetData();
    } finally {
      // setIsLoading(false);
    }
  }, [reportRequests01]);

  useEffect(() => {
    loadFilterReports();
  }, [loadFilterReports]);

  const loadDataReports = useCallback(
    async (auto_refresh) => {
      if (!reportRequests02) return;

      setIsLoading(true);
      if (!auto_refresh) setIsLoading2(true);
      try {
        let data = await getBatchReports(reportRequests02);
        data = get(data, "reports", []); 
       
      setReports02(data);
    } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
        if (!auto_refresh) setIsLoading2(false);
      }
    },
    [reportRequests02]
  );
  

  useEffect(() => {
    loadDataReports();
  }, [loadDataReports]);

 




  const commonFilter = useCallback(
    (report, otherFilter) => {
      if (isEmpty(report)) return {};

      const rows = report.rows.filter((row) => {
        const matchWidgetSourceId = selectedSourceId
          ? row[DIMENSION_ITEM_SOURCE_ID] === selectedSourceId
          : true;
        const matchWidgetSourceStreamId = selectedSourceStreamId
          ? row[DIMENSION_ITEM_SOURCE_STREAM_ID] === selectedSourceStreamId
          : true;
        const matchHostName = selectedPartner
          ? row[DIMENSION_HOSTNAME] === selectedPartner
          : true;
        const matchItemOwner = selectedItemOwner
          ? row[DIMENSION_ITEM_BRAND] === selectedItemOwner
          : true;
        const matchItemId = selectedItemId
          ? row[DIMENSION_ITEM_ID] === selectedItemId
          : true;

        const matchIgnoreHostNames = !ignoreHostNames.includes(
          row[DIMENSION_HOSTNAME]
        );
        const matchOtherFilter = otherFilter ? otherFilter(row) : true;

        return (
          matchWidgetSourceId &&
          matchWidgetSourceStreamId &&
          matchHostName &&
          matchIgnoreHostNames &&
          matchOtherFilter &&
          matchItemOwner &&
          matchItemId
        );
      });

      return {
        ...report,
        rows,
      };
    },
    [
      selectedPartner,
      selectedItemOwner,
      selectedSourceId,
      selectedSourceStreamId,
      selectedItemId,
    ]
  );

  const filtersReportData = useMemo(() => {
    const report = reviseReport(reports01[0]);
   

    return report;
  }, [reports01]);
  // console.log('filtersReportData', filtersReportData);



  
  const OverviewReportData = useMemo(() => {
    return reviseReport(reports02[0]);
  }, [reports02]);

  const DurationReportData = useMemo(() => {
    return reviseReport(reports03[0]);
  }, [reports03]);

  const contentViewsReportData = useMemo(() => {
    const revisedReport = reviseReport(reports02[0]);
    return revisedReport;
  }, [reports02]);


  const topContentAddedIds = useMemo(() => {
    const revisedReport = reviseReport(reports02[1]);
    return get(revisedReport, "rows", []).slice(0, 20).map(row => row[DIMENSION_ITEM_ID]);
  }, [reports02]);
 

  const topContentAddedDataReportRequest = useMemo(() => {
    if ( isEmpty(topContentAddedIds)) return;
    const commonFilters = [];

    if(domain){
      commonFilters.push(buildStringFilter(DIMENSION_ITEM_BRAND, domain));
    }
    if (selectedPartner)
      commonFilters.push(
        buildStringFilter(
          DIMENSION_INFOBOARD_BRAND,
          selectedPartner
        )
      );

  

    if (selectedItemOwner)
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_BRAND, selectedItemOwner)
      );
    return [      
      // 0: Top content added to stack
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
        
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [         
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([],           
          [
            ...commonFilters,
            buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_PLUG_ITEM, EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS, EVENT_VIEW_ITEM_CLICKED]),
            buildInListFilter(DIMENSION_ITEM_ID, topContentAddedIds)
        ]),      
      },
    ];
  }, [dateStrings, topContentAddedIds]);
  // load top data content added to stack
  useEffect(() => {
    // topContentAddedDataReportRequest
    const loadTopContentAddedStats = async ()=> {
      try {
        setIsLoading(true);
        if(topContentAddedDataReportRequest){
          const result = await getBatchReports(topContentAddedDataReportRequest);
          const data = reviseReport(get(result, 'reports[0]'));
          setTopContentAddedToStackReportData(get(data, "rows",[]));
        }else{
          setTopContentAddedToStackReportData([]);
        }
      } catch (error) {       
      
      } 
      finally{
        setIsLoading(false);
      }
    };
    loadTopContentAddedStats();
   
  }, [topContentAddedDataReportRequest]);
  // console.log('topContentAddedToStackReportData', topContentAddedToStackReportData);

  const usersReportData = useMemo(() => {
    return reviseReport(reports02[1]);
  }, [reports02]);

  const contentActiveViewsReportData = useMemo(() => {
    return reviseReport(reports02[2]);
  }, [reports02]);

  const contentClicksReportData = useMemo(() => {
    return reviseReport(reports02[3]);
  }, [reports02]);

  const availablePartners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_INFOBOARD_BRAND]: row[DIMENSION_INFOBOARD_BRAND],
      })),
      DIMENSION_INFOBOARD_BRAND
    );
  }, [filtersReportData]);

  const availableItemOwners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_ITEM_BRAND]: row[DIMENSION_ITEM_BRAND],
      })),
      DIMENSION_ITEM_BRAND
    );
  }, [filtersReportData]);

  const contentViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const rowsGroupedByDate = contentViewsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date])
          result[date] = {
            [EVENT_VIEW_ITEM_DETAILS]: 0,
            [EVENT_VIEW_ITEM_IMPRESSION]: 0,
          };
        result[date].date = date;

        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
          result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
            result[date][EVENT_VIEW_ITEM_IMPRESSION],
            current[METRIC_EVENT_COUNT],
          ]);
        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
          result[date][EVENT_VIEW_ITEM_DETAILS] = sum([
            result[date][EVENT_VIEW_ITEM_DETAILS],
            current[METRIC_EVENT_COUNT],
          ]);

        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS],
    });
  }, [contentViewsReportData]);
  // Content Impressions

  const onSourceChange = useCallback(
    (value) => {
      setSelectedSourceId(value);
      setParam(PARAM_WIDGET_SOURCE, value);
      setSelectedSourceStreamId(null);
      removeParam(PARAM_WIDGET_STREAM);
    },
    [removeParam, setParam]
  );

  const onSourceStreamChange = useCallback(
    (value) => {
      setSelectedSourceStreamId(value);
      setParam(PARAM_WIDGET_STREAM, value);
    },
    [setParam]
  );

  const onHostChange = useCallback(
    (value, option) => {
      setSelectedPartner(value);
      setParam(PARAM_WIDGET_PARTNER, value);
    },
    [setParam]
  );

  const onItemOwnerChange = useCallback(
    (value, option) => {
      setSelectedItemOwner(value);
      setParam(PARAM_WIDGET_ITEM_OWNER, value);
    },
    [setParam]
  );

  const onContentChange = useCallback(
    (value, option) => {
      setSelectedItemId(value);
      setParam(PARAM_WIDGET_ITEM_ID, value);
    },
    [setParam]
  );

  const adImpressionsEnabled =
    !selectedSourceStreamId && !selectedItemOwner && !selectedItemId;
  const context = useMemo(() => {
    return {
      clicksEnabled: hasProductRight,
      adImpressionsEnabled,
    };
  }, [hasProductRight, adImpressionsEnabled]);

  return (
    <PlugitProvider value={context}>
      <Spin spinning={isLoading || isLoading2}>
        <Row gutter={GRID_GUTTER} className="xl:justify-left">
        
          <Col xs={12} lg={6} xl={4} className="mb-8">
            {!isPrintMode ? (
              <Select
                value={selectedPartner}
                onChange={onHostChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All partners</Select.Option>
                {availablePartners.map((item) => (
                  <Select.Option
                    value={item[DIMENSION_INFOBOARD_BRAND]}
                    key={item[DIMENSION_INFOBOARD_BRAND]}
                   
                  >
                    <Space>
                      <Favicon url={item[DIMENSION_INFOBOARD_BRAND]} />
                      {item[DIMENSION_INFOBOARD_BRAND]}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <p>
                <span className="font-semibold">Partner: </span>
                <span>
                  {availablePartners.find(
                    (widgetSource) =>
                      widgetSource[DIMENSION_HOSTNAME] === selectedPartner
                  )?.[DIMENSION_HOSTNAME] || "All"}
                </span>
              </p>
            )}
          </Col>
       
        </Row>


        <OverviewSection
          domain={domain}
          dateStrings={dateStrings}
          OverviewReportData={OverviewReportData}
          contentViewsReportData={contentViewsReportData}
          usersReportData={usersReportData}
          contentViewsTimeSeriesData={contentViewsTimeSeriesData}
          DurationReportData={DurationReportData}
          // visitorOvertimeReportData={visitorOvertimeReportData}
          // deviceTypesReportData={deviceTypesReportData}
        />
        <Divider />

        {/* <TopViewsSection
          topContentAddedToStackReportData={topContentAddedToStackReportData}
          // topPageViewsReportData={topPageViewsReportData}
          // trafficSourcesReportData={trafficSourcesReportData}
        /> */}
         <DailyTrafficSection
          dateStrings={dateStrings}        
          contentViewsReportData={contentViewsReportData}
          usersReportData={usersReportData}
        />
      </Spin>
    </PlugitProvider>
  );
};

Shops.propTypes = {};

export default Shops;
