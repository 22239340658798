import React, { useMemo } from "react";
import { cloneDeep, isEmpty, sum, get } from "lodash";
import { useContentViewsContext } from "../../ContentViewsContext";
import { GRID_GUTTER, LABEL_INACTIVE, TOOLTIP_PUBLICATION } from "constants/ui";
import { Card, Col, Row, Space, Typography, Tooltip } from "antd";
import TableChart from "components/charts/TableChart";
import { DIMENSION_ITEM_SOURCE } from "constants/customDimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_EVENT_NAME } from "constants/dimensions";
import { EVENT_VIEW_ITEM_IMPRESSION } from "constants/customEvents";
import {
  SOURCE_LIGHTWEIGHT,
  SOURCE_MOBILE,
  SOURCE_PLUGILO,
  SOURCE_PLUGIT,
  SOURCE_WIDGET,
} from "constants/contentSources";
import { emerald300, indigo300, purple300, sky300 } from "constants/colors";
import Icon from "components/Icon";
import PieChart from "components/charts/PieChart";
import { formatNumber } from "services/numberService";
import Text from "antd/lib/typography/Text";
import Inactive from "components/Inactive";

function Publications() {
  const {
    summaryReport,
    emailSourcesMap,
    emailEventsMap,
    useSendInBlueReport,
    sendInBlueReport,
  } = useContentViewsContext();

  const plugiloCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_LIGHTWEIGHT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const widgetCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        (row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET ||
          row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGIT)
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);
  const mobileCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_MOBILE
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const emailSources = useMemo(() => {
    const result = Object.values(emailSourcesMap).map((emailSource) => {
      const hasEvents =
        ((emailEventsMap[emailSource.key] || {}).activeViews || 0) >=
        emailSource.triggerThreshold;
      return {
        ...emailSource,
        sent: hasEvents ? emailSource.sent : 0,
      };
    });

    if (useSendInBlueReport) {
      // hard code for send in blue
      return [
        {
          key: "premiumemail",
          displayName: "DCI Premium Email",
          names: ["PremiumEmail"],
          sent: get(sendInBlueReport, "statistics.campaignStats[0].sent", 0),
          triggerThreshold: 50,
        },
        ...Object.values(result),
      ];
    }

    return result;
  }, [emailSourcesMap, useSendInBlueReport, emailEventsMap, sendInBlueReport]);

  const displayEmailSources = useMemo(() => {
    const sourceMap = cloneDeep(emailSources).reduce((prev, current) => {
      if (prev[current.displayName]) {
        prev[current.displayName].sent =
          (prev[current.displayName].sent || 0) + (current.sent || 0);
      } else prev[current.displayName] = current;
      return prev;
    }, {});

    return Object.values(sourceMap);
  }, [emailSources]);

  const emailCount = useMemo(() => {
    return sum(emailSources.map((emailSource) => emailSource.sent));
  }, [emailSources]);

  const pieChartData = useMemo(() => {
    const total = plugiloCount + widgetCount + emailCount + mobileCount;
    const plugiloPercent = total ? (plugiloCount / total) * 100 : null;
    const widgetPercent = total ? (widgetCount / total) * 100 : null;
    const emailPercent = total ? (emailCount / total) * 100 : null;
    const mobilePercent = total ? (mobileCount / total) * 100 : null;

    return [
      {
        name: "plugilo",
        value: plugiloCount,
        color: sky300,
        percent: plugiloPercent,
      },
      {
        name: "Widgets",
        value: widgetCount,
        color: indigo300,
        percent: widgetPercent,
      },
      {
        name: "Mobile",
        value: mobileCount,
        color: emerald300,
        percent: mobilePercent,
      },

      {
        name: "Emails",
        value: emailCount,
        color: purple300,
        percent: emailPercent,
      },
    ];
  }, [emailCount, plugiloCount, widgetCount, mobileCount]);

  return (
    <section className="mt-8">
      <Tooltip title={<span className="text-xxs">{TOOLTIP_PUBLICATION}</span>}>
        <Space className="mb-2">
          <span className="text-xl font-semibold mb-0">Publications</span>{" "}
          (Number of sent emails & Impressions)
          <Icon name="info-circle" />
        </Space>
      </Tooltip>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12}>
          <Space
            direction="vertical"
            className="w-full lg:h-full flex flex-col"
            size={GRID_GUTTER}
          >
            <Card size="small">
              <TableChart
                rowKey={DIMENSION_ITEM_SOURCE}
                dataSource={[
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Portal",
                    [METRIC_EVENT_COUNT]: plugiloCount,
                  },
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Widgets",
                    [METRIC_EVENT_COUNT]: widgetCount,
                  },
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Mobile",
                    [METRIC_EVENT_COUNT]: mobileCount,
                  },
                ]}
                columns={[
                  {
                    title: "plugilo Source",
                    dataIndex: DIMENSION_ITEM_SOURCE,
                    key: DIMENSION_ITEM_SOURCE,
                    ellipsis: true,
                  },
                  {
                    title: "Impressions",
                    dataIndex: METRIC_EVENT_COUNT,
                    key: METRIC_EVENT_COUNT,
                    width: 100,
                    align: "right",
                    render: (value) => {
                      return value ? formatNumber(value) : <Inactive />;
                    },
                  },
                ]}
              />
            </Card>

            {!isEmpty(displayEmailSources) && (
              <Card size="small">
                <TableChart
                  rowKey="key"
                  dataSource={displayEmailSources}
                  columns={[
                    {
                      title: "Newsletter sources",
                      dataIndex: "displayName",
                      key: "displayName",
                      ellipsis: true,
                    },
                    {
                      title: "Emails (ca.)",
                      dataIndex: "sent",
                      key: "sent",
                      width: 100,
                      align: "right",
                      render: (value) => {
                        return value ? formatNumber(value) : <Inactive />;
                      },
                    },
                  ]}
                />
              </Card>
            )}
          </Space>
        </Col>

        <Col xs={24} lg={12}>
          <Card size="small" className="text-center lg:h-full mt-8 lg:mt-0">
            <div className="h-80">
              <PieChart
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                colors={[sky300, indigo300, purple300]}
                showLegends={false}
              />
            </div>
            <Space size={40} className="flex-wrap justify-center">
              {pieChartData.map((item) => (
                <Space direction="vertical" size={4} key={item.name}>
                  <Space align="baseline">
                    <Typography.Title
                      level={4}
                      style={{ color: item.color, margin: 0 }}
                    >
                      {item.value ? formatNumber(item.value) : LABEL_INACTIVE}
                    </Typography.Title>
                    {Boolean(item.percent) && (
                      <Text type="secondary">
                        ({formatNumber(item.percent)}%)
                      </Text>
                    )}
                  </Space>
                  <Text level={5}>{item.name}</Text>
                </Space>
              ))}
            </Space>
          </Card>
        </Col>
      </Row>
    </section>
  );
}

Publications.propTypes = {};

export default Publications;
